<template>
  <div
    class="lead-list"
    :style="cssProps"
  >
    <Toolbar
      :active-filters-count="activeFiltersCount"
      :list-options="listOptions"
      :refetch-parent="refetchParent"
      :leads-view-selected="leadsViewSelected"
      @exportLeads="downloadLeads"
      @changeView="changeView"
      @showImport="showContactImportDialog=true"
      @toggleLeadListTool="toggleLeadListTool"
    />
    <!-- Lead List -->
    <div
      class="d-flex"
      :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
      :style="{'gap': isAdditionalFilterOpen ? '20px' : hasActiveLeadListTool ? '10px' : '0px'}"
    >
      <v-slide-x-transition>
        <div
          v-if="hasActiveLeadListTool"
          class="d-flex"
          :style="{
            'gap': isAdditionalFilterOpen ? '10px' : '0px',
            'max-height': $vuetify.breakpoint.lgAndDown ? 'calc(100vh - 245px)' : '1150px'
          }"
          :class="{
            'list-tool-width':$vuetify.breakpoint.mdAndUp && !isAdditionalFilterOpen,
            'list-tool-double-width':$vuetify.breakpoint.mdAndUp && isAdditionalFilterOpen,
            'w-full mb-10':$vuetify.breakpoint.smAndDown
          }"
        >
          <BulkInvitation
            v-if="leadListOptionDetails.invitation.isOpen"
            class="h-full"
            :class="{
              'list-tool-width':$vuetify.breakpoint.mdAndUp,
              'w-full':$vuetify.breakpoint.smAndDown
            }"
            style="overflow:scroll"
            :selected-lead-ids="selectedLeadIds"
            :has-crm="isCrmActive"
            :crm-name="readableCrmName"
            :has-applicable-leads="hasLeads"
            @close="toggleLeadListTool('invitation')"
            @updateFetchIdsParameters="updateFetchIdsParameters"
            @enableImport="showContactImportDialog=true"
          >
            <template slot="selectAll">
              <v-card
                outlined
                style="border-radius: 10px !important"
              >
                <v-card-text class="pa-2">
                  <div v-if="!isSelectAllLeadsVisible">
                    <span v-html="$t('lead-list.some-contacts-selected', { amount: selectedItemsLength })" />
                  </div>
                  <div v-else-if="isAllLeadsSelected">
                    <span v-html="$t('lead-list.all-contacts-selected', { amount: selectedItemsLength })" />

                    <v-btn
                      depressed
                      text
                      small
                      color="primary"
                      class="w-full mt-2"
                      @click="deselectAllItems"
                    >
                      {{ $t('buttons.lead-list.deselect') }}
                    </v-btn>
                  </div>
                  <div v-else>
                    <span v-html="$t('lead-list.some-contacts-selected', { amount: selectedItemsLength })" />
                    <v-btn
                      depressed
                      text
                      small
                      color="primary"
                      class="w-full mt-2"
                      :loading="isAllLeadsLoading"
                      :disabled="isAllLeadsLoading"
                      @click="selectAllItems"
                    >
                      {{ $t('buttons.lead-list.select-filtered') }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </BulkInvitation>

          <DeleteLeads
            v-if="leadListOptionDetails.deleteLeads.isOpen"
            class="h-full"
            :class="{
              'list-tool-width':$vuetify.breakpoint.mdAndUp,
              'w-full':$vuetify.breakpoint.smAndDown
            }"
            style="overflow:scroll"
            :selected-items-length="selectedItemsLength"
            :trigger-reset-delete-leads="triggerResetDeleteLeads"
            :is-deleting-leads="isDeletingLeads"
            :has-crm="isCrmActive"
            :crm-name="readableCrmName"
            @triggeredResetDeleteLeads="triggeredResetDeleteLeads"
            @close="toggleLeadListTool('deleteLeads')"
            @updateFetchIdsParameters="updateFetchIdsParameters"
            @deleteLeads="deleteLeads"
          >
            <template slot="selectAll">
              <v-card
                outlined
                style="border-radius: 10px !important"
              >
                <v-card-text class="pa-2">
                  <div v-if="!isSelectAllLeadsVisible">
                    <span v-html="$t('lead-list.some-contacts-selected', { amount: selectedItemsLength })" />
                  </div>
                  <div v-else-if="isAllLeadsSelected">
                    <span v-html="$t('lead-list.all-contacts-selected', { amount: selectedItemsLength })" />

                    <v-btn
                      depressed
                      text
                      small
                      color="primary"
                      class="w-full mt-2"
                      @click="deselectAllItems"
                    >
                      {{ $t('buttons.lead-list.deselect') }}
                    </v-btn>
                  </div>
                  <div v-else>
                    <span v-html="$t('lead-list.some-contacts-selected', { amount: selectedItemsLength })" />
                    <v-btn
                      depressed
                      text
                      small
                      color="primary"
                      class="w-full mt-2"
                      :loading="isAllLeadsLoading"
                      :disabled="isAllLeadsLoading"
                      @click="selectAllItems"
                    >
                      {{ $t('buttons.lead-list.select-filtered') }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </DeleteLeads>

          <AssignLeads
            v-if="leadListOptionDetails.assignLeads.isOpen"
            class="h-full"
            :class="{
              'list-tool-width':$vuetify.breakpoint.mdAndUp,
              'w-full':$vuetify.breakpoint.smAndDown
            }"
            style="overflow:scroll"
            :selected-items-length="selectedItemsLength"
            :is-assigning-leads="isAssigningLeads"
            :has-crm="isCrmActive"
            :crm-name="readableCrmName"
            :trigger-reset-assign-leads="triggerResetAssignLeads"
            @close="toggleLeadListTool('assignLeads')"
            @updateFetchIdsParameters="updateFetchIdsParameters"
            @assignLeads="assignLeads"
          >
            <template slot="selectAll">
              <v-card
                outlined
                style="border-radius: 10px !important"
              >
                <v-card-text class="pa-2">
                  <div v-if="!isSelectAllLeadsVisible">
                    <span v-html="$t('lead-list.some-contacts-selected', { amount: selectedItemsLength })" />
                  </div>
                  <div v-else-if="isAllLeadsSelected">
                    <span v-html="$t('lead-list.all-contacts-selected', { amount: selectedItemsLength })" />

                    <v-btn
                      depressed
                      text
                      small
                      color="primary"
                      class="w-full mt-2"
                      @click="deselectAllItems"
                    >
                      {{ $t('buttons.lead-list.deselect') }}
                    </v-btn>
                  </div>
                  <div v-else>
                    <span v-html="$t('lead-list.some-contacts-selected', { amount: selectedItemsLength })" />
                    <v-btn
                      depressed
                      text
                      small
                      color="primary"
                      class="w-full mt-2"
                      :loading="isAllLeadsLoading"
                      :disabled="isAllLeadsLoading"
                      @click="selectAllItems"
                    >
                      {{ $t('buttons.lead-list.select-filtered') }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </AssignLeads>

          <FilterSettings
            v-if="leadListOptionDetails.filter.isOpen"
            :has-tool="hasTool"
            :can-close="leadListOptionDetails.filter.isOpen && !leadListOptionDetails.invitation.isOpen"
            filter-button-label="Weitere Filter"
            class="h-full"
            :class="{
              'list-tool-width':$vuetify.breakpoint.mdAndUp,
              'w-full':$vuetify.breakpoint.smAndDown
            }"
            :has-crm="isCrmActive"
            :crm-name="readableCrmName"
            @filtersChanged="onFiltersChanged"
            @close="toggleLeadListTool('filter')"
          />
          <ColumnSettingsTool
            v-if="leadListOptionDetails.column.isOpen"
            :column-settings="columnSettings"
            class="h-full"
            :class="{
              'list-tool-width':$vuetify.breakpoint.mdAndUp,
              'w-full':$vuetify.breakpoint.smAndDown
            }"
            @columnSettingsChanged="onColumnSettingsChanged"
            @close="toggleLeadListTool('column')"
          />
        </div>
      </v-slide-x-transition>
      <List
        :style="{ display: leadsViewSelected === 'list' ? 'block' : 'none' }"
        :class="{
          'list-width':$vuetify.breakpoint.mdAndUp && hasActiveLeadListTool && !isAdditionalFilterOpen,
          'list-double-width':$vuetify.breakpoint.mdAndUp && isAdditionalFilterOpen,
          'w-full':$vuetify.breakpoint.smAndDown
        }"
        :leads="leads"
        :commission="commission"
        :show-select="showListSelect"
        :loading="loading"
        :server-items-length="serverItemsLength"
        :select-items="selectListItems"
        :trigger-select-all-items="triggerSelectAllItems"
        :trigger-deselect-all-items="triggerDeselectAllItems"
        :list-options="listOptions"
        :active-filters-count="activeFiltersCount"
        :column-settings="columnSettings"
        @allVisibleItemsSelected="allVisibleItemsSelected"
        @allItemsSelected="allItemsSelected"
      />

      <!-- Lead Cards -->
      <LeadCards
        :style="{ display: leadsViewSelected === 'pipeline' ? 'block' : 'none' }"
        :class="{
          'list-width':$vuetify.breakpoint.mdAndUp && hasActiveLeadListTool && !isAdditionalFilterOpen,
          'list-double-width':$vuetify.breakpoint.mdAndUp && isAdditionalFilterOpen,
          'w-full':$vuetify.breakpoint.smAndDown
        }"
        :leads-by-status="leadsByStatus"
        :leads-by-status-loading="leadsByStatusLoading"
        :users="users"
        :commission="commission"
        :list-options="listOptions"
        :column-settings="columnSettings"
        :items-per-page="itemsPerPage"
        :sort-leads-by-position="sortLeadsByPosition"
        @updateLeadsPosition="updateLeadsPosition"
        @getLeadsByStatus="getLeadsByStatus"
      />
    </div>

    <import
      :refetch-parent="refetchParent"
      :show-import-dialog="showContactImportDialog"
      @cancel="showContactImportDialog=false"
    />
  </div>
</template>

<script>
import crmMixin from '@/mixins/crm'
import featureMixin from '@/mixins/feature'
import currencyMixin from '@/mixins/currency'
import brandingMixin from '@/mixins/branding'
import List from './List.vue'
import { DEFAULT_COLUMN_SETTINGS } from '../configs'
import { getActiveFilterCount, restoreAllFilters, persistFilter, restoreFilter, resetAllFilters } from '../helper/filter'
import { TOOLS_WITH_DOWNLOADS } from '@/lib/tools'
import { InvitationContactFilter } from '../enums/Invitation'
import DeleteLeads from './DeleteLeads.vue'
import AssignLeads from './AssignLeads.vue'
import { exportLeads } from '@/lib/export'
import Toolbar from './Toolbar.vue'
import LeadCards from './Cards/LeadCards.vue'

export default {
  components: {
    Import: () => import(/* webpackChunkName: "import" */ '../Import'),
    FilterSettings: () => import('./FilterSettings'),
    BulkInvitation: () => import('./BulkInvitation'),
    ColumnSettingsTool: () => import('./ColumnSettingsTool'),
    List,
    DeleteLeads,
    AssignLeads,
    Toolbar,
    LeadCards
  },
  mixins: [currencyMixin, brandingMixin, featureMixin, crmMixin],
  props: {
    parentTriggerSelectAllItems: {
      type: Boolean,
      default: false
    },
    leads: {
      type: Array,
      default: () => []
    },
    leadsByStatus: {
      type: Array,
      default: () => []
    },
    users: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    leadsByStatusLoading: {
      type: Boolean,
      default: false
    },
    isDeletingLeads: {
      type: Boolean,
      default: false
    },
    isAssigningLeads: {
      type: Boolean,
      default: false
    },
    serverItemsLength: {
      type: Number,
      default: 0
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    refetchLeads: {
      type: Function,
      default: () => {}
    },
    selectAllLeadsState: {
      type: String,
      default: 'idle'
    },
    selectedLeadIds: {
      type: Array,
      default: () => []
    },
    selectItems: {
      type: Function,
      default: () => {}
    },
    sortLeadsByPosition: {
      type: Function,
      default: () => {}
    },
    landingpageTypes: {
      type: Array,
      default: () => []
    },
    triggerResetDeleteLeads: {
      type: Boolean,
      default: false
    },
    triggerResetAssignLeads: {
      type: Boolean,
      default: false
    },
    commission: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      leadsViewSelected: JSON.parse(sessionStorage.getItem('leadsViewSelected')),
      activeFiltersCount: 0,
      showContactImportDialog: false,
      columnSettings: DEFAULT_COLUMN_SETTINGS,
      listOptions: {
        options: {
          sortDesc: [true],
          itemsPerPage: this.itemsPerPage
        },
        search: '',
        filters: { },
        landingpageSlugFilter: ''
      },
      leadListOptionDetails: {
        filter: {
          isOpen: false,
          close: () => this.removeFilterParam()
        },
        invitation: {
          isOpen: false,
          open: () => this.showAvailableLeads(),
          close: () => this.showAvailableLeads()
        },
        column: {
          isOpen: false
        },
        deleteLeads: {
          isOpen: false,
          open: () => this.showAvailableLeads(),
          close: () => this.showAvailableLeads()
        },
        assignLeads: {
          isOpen: false,
          open: () => this.showAvailableLeads(),
          close: () => this.showAvailableLeads()
        }
      },
      triggerSelectAllItems: false,
      triggerDeselectAllItems: false,
      isSelectAllLeadsVisible: false,
      showListSelect: false,
      allVisibleItemsChecked: false
    }
  },
  computed: {
    isPipelineView () {
      return this.leadsViewSelected === 'pipeline'
    },
    selectedItemsLength () {
      return this.selectedLeadIds.length
    },

    isAdditionalFilterOpen () {
      return (this.leadListOptionDetails.invitation.isOpen ||
              this.leadListOptionDetails.deleteLeads.isOpen ||
              this.leadListOptionDetails.assignLeads.isOpen
      ) && this.leadListOptionDetails.filter.isOpen
    },

    hasActiveLeadListTool () {
      return Object.keys(this.leadListOptionDetails).some(key => this.leadListOptionDetails[key].isOpen)
    },

    availableTools () {
      return TOOLS_WITH_DOWNLOADS.filter(({ type }) => this.hasTool(type))
    },
    cssProps () {
      return {
        '--theme-color-red': this.$vuetify.theme.themes.light.red
      }
    },

    isAllLeadsSelected () {
      return this.selectAllLeadsState === 'selected'
    },
    isAllLeadsLoading () {
      return this.selectAllLeadsState === 'loading'
    },
    hasLeads () {
      return this.leads.length > 0
    },
    activeLeadListToolSlug () {
      return Object.keys(this.leadListOptionDetails).find(key => key !== 'filter' && this.leadListOptionDetails[key].isOpen)
    },
    excludedCrmTags () {
      return {
        PROPSTACK: ['onofficeCrmTags', 'flowfactCrmTags'],
        ONOFFICE: ['propstackCrmTags', 'flowfactCrmTags'],
        FLOWFACT: ['propstackCrmTags', 'onofficeCrmTags']
      }[this.readableCrmName.toUpperCase()] || []
    }
  },
  watch: {
    readableCrmName () {
      this.columnSettings = this.restoreColumnSettings()
    },
    leadsViewSelected (newValue) {
      if (newValue === 'pipeline') {
        this.leadListOptionDetails.invitation.isOpen = false
      }

      this.columnSettings = this.restoreColumnSettings()
    },
    parentTriggerSelectAllItems () {
      if (this.parentTriggerSelectAllItems && this.isAdditionalFilterOpen) {
        this.triggerSelectAllItems = true
        this.$emit('triggeredSelectAllItems')
      }
    },
    listOptions: {
      handler () {
        if (this.triggerSelectAllItems) this.deselectAllItems()

        // Sort options
        const { sortBy, sortDesc, page, itemsPerPage } = this.listOptions.options

        // Search
        let searchString = ''
        if (this.listOptions.search && this.listOptions.search.length > 2) {
          searchString = this.listOptions.search
        }

        this.refetchLeads({
          sortBy: sortBy[0],
          sortDesc: sortDesc[0],
          page,
          itemsPerPage,
          search: searchString,
          filters: this.listOptions.filters,
          ...(this.listOptions.landingpageSlugFilter && { landingpageSlugFilter: this.listOptions.landingpageSlugFilter })
        })
      },
      deep: true
    }
  },
  mounted () {
    this.setInitialRoute()
    this.setInitialFilter()
  },
  created () {
    const filters = restoreAllFilters({ hasCrm: this.isCrmActive, availableTools: this.availableTools })
    const activeFiltersCount = getActiveFilterCount({ hasCrm: this.isCrmActive, availableTools: this.availableTools })
    this.onFiltersChanged({ filters, activeFiltersCount })
    this.columnSettings = this.restoreColumnSettings()
  },
  methods: {
    restoreColumnSettings () {
      let columns = DEFAULT_COLUMN_SETTINGS
      // filter column only for tools/lps the user has
      columns = columns.filter(column =>
        column.value.includes('conversionCountByType') ? !!this.hasTool(column.value.split('.')[1]) : true
      )

      if (!this.isCrmActive || this.excludedCrmTags.length === 0) {
        const excludedFields = new Set(['propstackCrmTags', 'flowfactCrmTags', 'onofficeCrmTags'])
        columns = columns.filter(column => !excludedFields.has(column.value))
      }

      if (this.isCrmActive && this.excludedCrmTags.length > 0) {
        const excludedFields = new Set(this.excludedCrmTags)
        columns = columns.filter(column => !excludedFields.has(column.value))
      }

      if (!this.leadsViewSelected || this.leadsViewSelected === 'pipeline') {
        const excludedFields = new Set([
          'name', 'currentStatus', 'createdAt', 'city', 'zip', 'email', 'exportedAt'
        ])
        // Filter fields only for the grid view
        columns = columns.filter(column => !excludedFields.has(column.value))
      }

      if (this.leadsViewSelected === 'list') {
        const excludedFields = new Set(['propertyValuation', 'commission'])
        columns = columns.filter(column => !excludedFields.has(column.value))
      }

      try {
        let cols = localStorage.getItem('leadList.columnSettings' + this.leadsViewSelected) || localStorage.getItem('leadList.columnSettings')
        cols = JSON.parse(cols)
        const isDifferent = columns.filter(({ value: header1 }) => !cols.some(({ value: header2 }) => header1 === header2))

        if (cols.length !== columns.length || isDifferent.length > 0) {
          return columns
        }

        if (Array.isArray(cols) && cols.some(col => col.visible)) {
          return cols
        }
      } catch {}
      return columns
    },
    setInitialRoute () {
      const { name, params } = this.$route

      if (name === 'leads-view') {
        const view = params.view

        if (!this.leadsViewSelected || this.leadsViewSelected !== view) {
          this.leadsViewSelected = view
          sessionStorage.setItem('leadsViewSelected', JSON.stringify(view))
        }
      }
    },
    changeView (view) {
      this.leadsViewSelected = view
      this.$router.push({ path: `/leads/${this.leadsViewSelected}` })

      // Always disable bulk assignLeads and delete leads when pipeline view
      if (this.isPipelineView) {
        this.leadListOptionDetails.assignLeads.isOpen = false
        this.leadListOptionDetails.deleteLeads.isOpen = false
      }
    },
    async downloadLeads (onlyNotExported = false) {
      await exportLeads({
        sortBy: this.listOptions.options.sortBy,
        sortDesc: this.listOptions.options.sortDesc,
        search: this.listOptions.options.search,
        filters: JSON.stringify(this.listOptions.options.filters),
        shouldExport: true,
        onlyNotExported
      })
      this.onExported()
    },
    triggeredResetDeleteLeads () {
      this.$emit('triggeredResetDeleteLeads')
    },
    deleteLeads () {
      this.$emit('deleteLeads')
    },
    triggeredResetAssignLeads () {
      this.$emit('triggeredResetAssignLeads')
    },
    assignLeads (userId) {
      this.$emit('assignLeads', userId)
    },
    getLeadsByStatus (statuses, page) {
      this.$emit('getLeadsByStatus', statuses, page)
    },
    updateLeadsPosition (listIds) {
      this.$emit('updateLeadsPosition', listIds)
    },
    selectListItems (data) {
      this.selectItems(data)
      if (this.allVisibleItemsChecked) {
        if (data.length < this.serverItemsLength) {
          this.isSelectAllLeadsVisible = true
        } else {
          this.isSelectAllLeadsVisible = false
          this.triggerSelectAllItems = false
        }

        this.allVisibleItemsChecked = false
      } else {
        this.isSelectAllLeadsVisible = false
        this.triggerSelectAllItems = false
      }
    },
    updateFetchIdsParameters (selectData) {
      this.listOptions.landingpageSlugFilter = selectData.landingpageSlug

      if (selectData.contactFilter === InvitationContactFilter.CUSTOM) {
        this.showListSelect = true
        if (selectData.showFilter) {
          this.showAdditionFilter(true)
        }
        this.$emit('updateFetchIdsParameters', { ...selectData, activeTool: this.activeLeadListToolSlug })
      } else if (selectData.contactFilter === InvitationContactFilter.CRM) {
        this.showListSelect = false
        this.showAdditionFilter(false)
        this.deselectAllItems()
        persistFilter('leadOriginImport', null)
        persistFilter('leadOriginConversion', null)
        const filters = restoreAllFilters({ hasCrm: this.isCrmActive, availableTools: this.availableTools })
        filters.leadOriginConversion = restoreFilter('leadOriginConversion', true, false)
        filters.leadOriginImport = restoreFilter('leadOriginImport', true, false)
        this.activeFiltersCount = 1
        this.listOptions.search = ''
        this.$emit('updateFetchIdsParameters', { ...selectData, filters, activeTool: this.activeLeadListToolSlug })
        this.onFiltersChanged({ filters, activeFiltersCount: this.activeFiltersCount })
      } else {
        this.showListSelect = false
        this.showAdditionFilter(false)
        this.deselectAllItems()

        const filters = restoreAllFilters({ hasCrm: this.isCrmActive, availableTools: this.availableTools })
        this.activeFiltersCount = getActiveFilterCount({ hasCrm: this.isCrmActive, availableTools: this.availableTools })
        this.listOptions.search = ''
        this.$emit('updateFetchIdsParameters', { ...selectData, filters, activeTool: this.activeLeadListToolSlug })
        this.onFiltersChanged({ filters, activeFiltersCount: this.activeFiltersCount })
      }
    },

    setInitialFilter () {
      if (Object.keys(this.$route.query).includes('filter')) {
        this.leadListOptionDetails.filter.isOpen = true
      }
    },
    showAdditionFilter (show) {
      const details = this.leadListOptionDetails.filter
      if (show) {
        details.isOpen = true
        if ('open' in details) details.open()
      } else {
        details.isOpen = false
        if ('close' in details) details.close()
      }
    },
    toggleLeadListTool (slug) {
      if (this.isAdditionalFilterOpen) {
        const sameTool = slug === this.activeLeadListToolSlug
        const details = this.leadListOptionDetails[this.activeLeadListToolSlug]

        const detailsFilter = this.leadListOptionDetails.filter
        details.isOpen = false
        detailsFilter.isOpen = false
        if ('close' in details) details.close()
        if ('close' in detailsFilter) detailsFilter.close()
        if (sameTool) return
      }
      const details = this.leadListOptionDetails[slug]
      if (details.isOpen) {
        details.isOpen = false
        if ('close' in details) details.close()
      } else {
        Object.keys(this.leadListOptionDetails).forEach(key => {
          if (this.leadListOptionDetails[key].isOpen) {
            this.leadListOptionDetails[key].isOpen = false
            if ('close' in this.leadListOptionDetails[key]) this.leadListOptionDetails[key].close()
          }
        })
        details.isOpen = true
        if ('open' in details) details.open()
      }
    },
    onColumnSettingsChanged (columnSettings) {
      this.columnSettings = columnSettings
      localStorage.setItem('leadList.columnSettings' + this.leadsViewSelected, JSON.stringify(columnSettings))
    },
    onFiltersChanged ({ filters, activeFiltersCount }) {
      this.listOptions.filters = { ...this.listOptions.filters, ...filters }
      this.activeFiltersCount = activeFiltersCount
    },
    refetchParent () {
      this.listOptions.options.sortBy = ['createdAt']
      const { sortBy, sortDesc, page, itemsPerPage, search } = this.listOptions.options
      this.refetchLeads({ sortBy: sortBy[0], sortDesc: sortDesc[0], page, itemsPerPage, search, filters: this.listOptions.filters })
    },
    hasTool (toolType) {
      return toolType === 'DOWNLOAD' || this.landingpageTypes.some(lT => lT.type === toolType)
    },

    showAvailableLeads () {
      resetAllFilters()
      const filters = restoreAllFilters({ hasCrm: this.isCrmActive, availableTools: this.availableTools })
      const activeFiltersCount = getActiveFilterCount({ hasCrm: this.isCrmActive, availableTools: this.availableTools })
      this.onFiltersChanged({ filters, activeFiltersCount })

      this.triggerDeselectAllItems = true
      this.triggerSelectAllItems = false
      this.isSelectAllLeadsVisible = false
      this.showListSelect = false
    },

    allVisibleItemsSelected ({ checked }) {
      this.allVisibleItemsChecked = checked
    },
    allItemsSelected (value) {
      this.triggerDeselectAllItems = false
      if (this.activeLeadListToolSlug) this.$emit('allItemsSelected', { isSelectAll: value, slug: this.activeLeadListToolSlug })
    },
    removeFilterParam () {
      if (Object.keys(this.$route.query).includes('filter')) {
        this.$router.push('/leads/' + this.leadsViewSelected)
      }
    },
    selectAllItems () {
      this.triggerDeselectAllItems = false
      this.triggerSelectAllItems = true
    },
    deselectAllItems () {
      this.triggerDeselectAllItems = true
      this.triggerSelectAllItems = false
    }
  }
}
</script>

<style>
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
  -webkit-box-shadow:none;
}

.option-container{
  height: 50px;
}

.option-btn  {
  width: 100%;
}

.option-btn .v-btn__content {
  padding-left: 10px;
  padding-right: 10px;
}

/* breakpoint md and up */
@media (min-width: 600px) {

  .lead-list__search{
    min-width: 350px;
    max-width: 350px;
  }

  .option-container {
    max-width: 120px;
    height: 100%;
  }
}

.lead-list__item {
  cursor: pointer;
}

.lead-list__item--new > td:first-child {
  border-left: 3px solid var(--theme-color-red);
}

.lead-list__item-title--wrap {
  white-space: normal;
}

.lead-list .v-list--dense .v-list-item .v-list-item__title,
.lead-list .v-list--dense .v-list-item .v-list-item__subtitle {
  font-weight: normal;
}

.lead-list__draggable {
  cursor: move;
}

.lead-list__preview {
  display: flex;
  align-items: center;
}

.lead-list__preview-item {
  margin-right: 16px;
}

.lead-list__preview-item:last-child {
  margin-right: none;
}

.camel-case {
    text-transform: unset !important;
    font-weight: normal;
}

.list-options .v-input--selection-controls .v-input__slot, .v-input--select{
  margin-bottom: 0px;
}

.list-options .v-text-field.v-text-field--enclosed{
  margin-top:0px !important;
}

.activate-window-step{
  max-width: 600px
}

.list-item-container{
  padding: 0px;
}

.list-item-container:hover{
  background-color: rgb(245, 245, 245);
}

.menu-list-item{
  padding-left: 15px;
  padding-right: 15px;
  height: 48px;
  display:flex;
  align-items:center;
  cursor: pointer;
}

.filter-container{
  position:relative;
}

.disabled-layer{
  height: 100%;
  width: 100%;
  cursor:pointer;
  position: absolute;
}

.lead-list .v-list--dense .v-list-item .v-list-item__title, .lead-list .v-list--dense .v-list-item .v-list-item__subtitle{
  max-width: 200px;
  overflow: hidden;
}

.lead-list__item-mobile--new{
  border-left: 3px solid var(--theme-color-red);
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 25px;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row:first-child{
   min-height: 48px;
}

.leadlist-icon-wrapper{
  min-width: 25px;
  width: 25px;
  max-width: 25px;
}

.list-tool-width{
  min-width: 360px;
  max-width: 360px;
}

.list-tool-double-width{
  min-width: 720px;
  max-width: 720px;
}

.list-width{
  min-width: calc(100% - 360px - 10px);
  max-width: calc(100% - 360px - 10px);
}

.list-double-width{
  min-width: calc(100% - 720px - 20px);
  max-width: calc(100% - 720px - 20px);
}
</style>
