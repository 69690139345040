<template>
  <step-container
    v-if="onboardingSepa"
    :has-steps="isOnboarding"
    :is-disabled="!isOnboarding"
    :required-data="requiredData"
    :data="data"
    :on-submit="submit"
    :name="'update-company-billing'"
    v-on="$listeners"
  >
    <billing-data
      v-if="isOnboarding"
      :data="data"
      is-onboarding
      @updateBillingAddress="updateBillingAddress"
    />

    <v-card
      v-else
      flat
    >
      <billing-data
        class="mx-4"
        :data="data"
        @updateBillingAddress="updateBillingAddress"
      />
    </v-card>
  </step-container>
</template>

<script>
import StepContainer from './StepContainer.vue'
import UPDATE_COMPANY_BILLING from './queries/UpdateCompanyBilling.gql'
import ADD_DOCUMENT_TO_ONBOARDING from './queries/AddDocumentToOnboarding.gql'
import COMPANY_DOCUMENTS from './queries/CompanyDocuments.gql'
import BillingData from '@/components/forms/BillingData'

export default {
  components: { StepContainer, BillingData },
  props: {
    companyId: {
      type: String,
      required: true
    },
    country: {
      type: String,
      required: true
    },
    customerNumber: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    address: {
      type: Object,
      required: true
    },
    billing: {
      type: Object,
      required: true
    },
    isOnboarding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: {
        onboardingSepa: undefined,
        customerNumber: this.customerNumber,
        hasSeparateBillingAddress: this.billing.hasSeparateBillingAddress,
        street: this.billing.address.street,
        zip: this.billing.address.zip,
        city: this.billing.address.city,
        companyStreet: this.address.street,
        companyZip: this.address.zip,
        companyCity: this.address.city,
        phone: this.billing.contact.phone,
        email: this.billing.contact.email,
        companyName: this.billing.contact.companyName || '',
        companyNameAddition: this.billing.contact.companyNameAddition,
        firstname: this.billing.contact.firstname,
        lastname: this.billing.contact.lastname,
        vatId: this.billing.vatId,
        vatIdRequestDate: this.billing.vatIdRequestDate,
        paymentMethod: this.billing.paymentMethod || (this.country === 'ch' ? 'INVOICE' : 'DIRECT_DEBIT'),
        iban: this.billing.iban,
        bic: this.billing.bic,
        sepaValidAsOf: this.billing.sepaValidAsOf,
        sepaInput: undefined,
        vatIdRequested: Boolean(this.billing.vatIdRequestDate),
        companyNameExceeded: false,
        companyCountry: this.country
      }
    }
  },
  watch: {
    onboardingSepa () {
      this.data.onboardingSepa = this.onboardingSepa
    }
  },
  methods: {
    updateBillingAddress (billingAddress) {
      const { zip, street, city } = billingAddress
      this.data.zip = zip
      this.data.street = street
      this.data.city = city
    },
    requiredData ({
      country,
      onboardingSepa,
      vatIdRequested,
      bic,
      iban,
      sepaValidAsOf,
      vatId,
      paymentMethod,
      vatIdRequestDate,
      hasSeparateBillingAddress,
      street, zip, city,
      phone, email, firstname, lastname
    }) {
      return {
        phone,
        email,
        firstname,
        lastname,
        ...(vatIdRequested ? { vatIdRequestDate } : { vatId }),
        ...(hasSeparateBillingAddress && { street, zip, city }),
        ...(country !== 'ch' && paymentMethod === 'DIRECT_DEBIT' && { iban, bic, sepaValidAsOf }),
        ...(paymentMethod === 'DIRECT_DEBIT' && { onboardingSepa: onboardingSepa.url })
      }
    },
    async submit ({
      sepaInput,
      vatIdRequested,
      sepaValidAsOf,
      vatId, vatIdRequestDate, paymentMethod, bic, iban, hasSeparateBillingAddress,
      companyName, companyNameAddition, firstname, lastname, email, phone,
      street, zip, city
    }) {
      await this.$apollo.mutate({
        mutation: UPDATE_COMPANY_BILLING,
        variables: {
          input: {
            companyId: this.companyId,
            paymentMethod,
            iban,
            bic,
            sepaValidAsOf,
            ...(vatIdRequested
              ? { vatIdRequestDate: vatIdRequestDate, vatId: undefined }
              : { vatId, vatIdRequestDate: undefined }),
            hasSeparateBillingAddress: hasSeparateBillingAddress || false,
            contact: { companyName, companyNameAddition, firstname, lastname, email, phone },
            ...(hasSeparateBillingAddress && { address: { street, zip, city } })
          }
        }
      })
      if (sepaInput) {
        await this.$apollo.mutate({
          mutation: ADD_DOCUMENT_TO_ONBOARDING,
          variables: {
            input: {
              companyId: this.companyId,
              document: {
                type: 'SEPA',
                file: sepaInput
              }
            }
          },
          update: (store, { data: { document } }) => {
            const data = store.readQuery({ query: COMPANY_DOCUMENTS, variables: { companyId: this.companyId } })
            data.onboardingDocuments = [...data.onboardingDocuments, { type: 'SEPA', file: document, __typename: 'ContractDocument' }]
            store.writeQuery({ query: COMPANY_DOCUMENTS, data, variables: { companyId: this.companyId } })
          }
        })
        this.data.sepaInput = undefined
      }
    }
  },
  apollo: {
    onboardingSepa: {
      query: COMPANY_DOCUMENTS,
      variables () {
        return {
          companyId: this.companyId
        }
      },
      update: data => data.onboardingDocuments.find(({ type }) => type === 'SEPA')?.file || {}
    }
  }
}
</script>
