<template>
  <SettingsCard
    v-if="users?.length > 0"
    :title="$t('settings.user.name')"
    :subtitle="$t('settings.user.subtitle')"
    icon="mdi-account-outline"
    :is-default-open="true"
  >
    <v-row
      class="ma-2"
    >
      <v-col
        cols="12"
        md="5"
      >
        <div class="font-weight-bold">
          {{ $t('settings.user.body-texts.0') }}
        </div>
        <div class="grey--text mb-2">
          {{ $t('settings.user.body-texts.1') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.user.body-texts.2') }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="7"
      >
        <Feature
          v-slot="{feature}"
          :feature-slug="featureNames.COMPANY_USER"
        >
          <div class="font-weight-bold">
            <div>{{ $t('settings.user.active-users', { current: currentUsers, max: feature?.config?.maxUsers }) }}<br></div>
            <div v-if="possibleUsers(feature?.config?.maxUsers) <= 0">
              {{ $t('settings.user.no-more-users') }}
            </div>
            <div v-else-if="possibleUsers(feature?.config?.maxUsers) === 1">
              {{ $t('settings.user.one-user') }}
            </div>
            <div v-else>
              {{ $t('settings.user.multiple-users', { count: possibleUsers(feature?.config?.maxUsers) }) }}
            </div>
          </div>
        </Feature>
        <Feature :feature-slug="featureNames.AUTHORIZATION">
          <template>
            <div class="my-4">
              {{ $t('settings.user.upselling.title') }}<br>
              <v-btn
                class="my-2"
                color="primary"
                to="/support"
              >
                {{ $t('buttons.ask-support.cta') }}
              </v-btn>
            </div>
          </template>
          <template v-slot:inactive="{feature}">
            <div v-if="feature?.isEnabled && !feature?.isUpsellingEnabled">
              <v-alert
                icon="mdi-lightbulb-on-20"
                text
                type="info"
                class="mt-4"
              >
                <p class="ma-0 font-weight-bold">
                  {{ $t('labels.our-tip') }}:
                </p>
                <div>
                  {{ $t('settings.user.expert-info') }}
                </div>
                <v-btn
                  class="mt-2"
                  color="primary"
                  :to="{ name: 'Settings', params: { section: 'contract' }, query: { showUpgrade: '1' } }"
                  @click="$tracking.event('Settings', 'Clicked', 'Upgrade', 'User Access')"
                >
                  {{ $t('buttons.upgrade-now.cta') }}
                </v-btn>
              </v-alert>
            </div>
            <div v-else>
              <div class="my-4">
                {{ $t('settings.user.upselling.title') }}<br>
                {{ $t('settings.user.upselling.subtitle') }}:
              </div>
              <AuthorizationBenefits />
              <div class="font-weight-bold my-6">
                {{ $t('settings.user.upselling.price', { price: price?.price, currency: price?.currency }) }}
              </div>
              <ActivateAuthPackageDialog :price="price" />
              <div class="caption pt-1 grey--text">
                {{ $t('settings.user.upselling.info') }}
              </div>
            </div>
          </template>
        </feature>
      </v-col>
    </v-row>
    <v-row class="ma-2">
      <v-col
        cols="12"
        md="5"
      >
        <div class="font-weight-bold">
          {{ $t('settings.user.user-texts.0') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.user.user-texts.1', { readablePlatformName }) }}
        </div>
        <Feature :feature-slug="featureNames.AUTHORIZATION">
          <div
            class="grey--text pt-2"
            v-html="$t('settings.user.user-texts.2')"
          />
        </Feature>
        <v-alert
          v-if="initialUsers > 3"
          icon="mdi-lightbulb-on-20"
          text
          class="mt-4 mr-10"
          type="info"
        >
          <p class="ma-0">
            {{ $t('alerts.settings.user.check-rights') }}
          </p>
          <p class="mt-2 mb-0">
            {{ $t('alerts.settings.user.info') }}
          </p>
        </v-alert>
      </v-col>
      <v-col
        cols="12"
        md="7"
      >
        <v-simple-table class="mb-6">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t('labels.name') }}
                </th>
                <Feature :feature-slug="featureNames.AUTHORIZATION">
                  <th class="text-left">
                    {{ $t('settings.user.labels.authorization') }}
                  </th>
                </Feature>
                <th class="text-right" />
              </tr>
            </thead>
            <tbody>
              <tr class="grey lighten-4">
                <td>{{ self.firstname }} {{ self.lastname }}</td>
                <Feature :feature-slug="featureNames.AUTHORIZATION">
                  <td>{{ self.role?.name }} </td>
                </Feature>
                <td class="text-right">
                  <router-link
                    to="/settings/profile"
                    class="px-8"
                  >
                    ({{ $t('settings.user.labels.me') }})
                  </router-link>
                </td>
              </tr>
              <tr
                v-for="(user, index) of otherUsers"
                :key="user.id"
                :class="index % 2 === 1 ? 'grey lighten-4' : ''"
                :style="(!user.role || user.role?.isAssignable) && 'cursor: pointer;'"
                @click="() => showEditUserDialog(user)"
              >
                <td>{{ user.firstname }} {{ user.lastname }} {{ user.isBlocked ? `[${$t('settings.user.labels.blocked')}]` : '' }}</td>
                <Feature :feature-slug="featureNames.AUTHORIZATION">
                  <td>{{ user.role?.name }} </td>
                </Feature>
                <td>
                  <div
                    v-if="user.id !== role.userId && (!user.role || user.role?.isAssignable)"
                    class="d-flex justify-end"
                  >
                    <Permission permission="user:write">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            class="mr-6"
                            v-on="on"
                          >
                            <v-icon :alt="$t('labels.edit')">
                              mdi-pencil-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('labels.edit') }}</span>
                      </v-tooltip>
                    </Permission>
                    <Permission permission="user:delete">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="error"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="() => showDeleteUserDialog(user)"
                          >
                            <v-icon :alt="$t('labels.remove')">
                              mdi-trash-can-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('labels.remove') }}</span>
                      </v-tooltip>
                    </Permission>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <Feature
          v-slot="{feature}"
          :feature-slug="featureNames.COMPANY_USER"
        >
          <Permission permission="user:create">
            <div>
              <v-btn
                class="w-full"
                :disabled="possibleUsers(feature?.config?.maxUsers) <= 0"
                @click="showCreateUserDialog"
              >
                <v-icon :alt="$t('labels.add')">
                  mdi-plus
                </v-icon>
                {{ $t('buttons.add-user.cta') }}
              </v-btn>
              <div class="caption pt-1 grey--text">
                {{ $t('labels.hint') }}: {{ $t('settings.user.add-users', { max: possibleUsers(feature?.config?.maxUsers) }) }}
              </div>
            </div>
          </Permission>
        </Feature>
      </v-col>
    </v-row>
    <Feature :feature-slug="featureNames.COMPANY_USER">
      <UpsertUserDialog
        :show="showUserDialog"
        :user="currentUser"
        :create="create"
        @close="showUserDialog = false"
      />
    </Feature>
    <DeleteUserDialog
      :user="currentUser"
      :show="showUserDeleteDialog"
      @close="showUserDeleteDialog = false"
    />
  </SettingsCard>
</template>

<script>
import USERS from './queries/Users.gql'
import PRICE from './queries/Price.gql'

import SettingsCard from '@/components/SettingsCard.vue'
import featureMixin from '@/mixins/feature'
import brandingMixin from '@/mixins/branding'
import DeleteUserDialog from './DeleteUserDialog.vue'
import UpsertUserDialog from './UpsertUserDialog.vue'
import ActivateAuthPackageDialog from './ActivateAuthPackageDialog.vue'
import AuthorizationBenefits from './AuthorizationBenefits.vue'
import { Feature } from 'feature-config'
import Permission from '@/components/Permission'

export default {
  components: {
    SettingsCard,
    DeleteUserDialog,
    UpsertUserDialog,
    ActivateAuthPackageDialog,
    AuthorizationBenefits,
    Feature,
    Permission
  },
  mixins: [featureMixin, brandingMixin],
  data () {
    return {
      users: [],
      currentUser: {},
      create: false,
      showUserDialog: false,
      showUserDeleteDialog: false,
      isLoading: false
    }
  },
  computed: {
    currentUsers () {
      return this.users.length
    },
    initialUsers () {
      return this.users.filter(user => user.role?.isInitial).length
    },
    otherUsers () {
      return this.users.filter(user => user.id !== this.role.userId)
    },
    self () {
      return this.users.find(user => user.id === this.role.userId)
    },
    isUpsellingEnabledForAuthorization () {
      const feature = this.$features.feature(this.featureNames.AUTHORIZATION)
      return feature?.isEnabled && feature?.isUpsellingEnabled
    }
  },
  methods: {
    possibleUsers (maxUsers) {
      return Math.max(0, maxUsers - this.currentUsers)
    },
    showCreateUserDialog () {
      this.create = true
      this.currentUser = {}
      this.showUserDialog = true
      this.$tracking.event('Settings', 'Clicked', 'Create User Dialog')
    },
    showDeleteUserDialog (user) {
      this.currentUser = user
      this.showUserDeleteDialog = true
      this.$tracking.event('Settings', 'Clicked', 'Delete User Dialog')
    },
    showEditUserDialog (user) {
      if (user.role && !user.role?.isAssignable) {
        return
      }
      if (!this.role.isAuthorizedFor('user:write')) {
        return
      }
      this.create = false
      this.currentUser = user
      this.showUserDialog = true
      this.$tracking.event('Settings', 'Clicked', 'Edit User Dialog')
    }
  },
  inject: ['role'],
  apollo: {
    users: {
      query: USERS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    price: {
      query: PRICE,
      variables () {
        return {
          slug: 'roles-and-permissions'
        }
      },
      skip () {
        return !this.role.isAuthorizedFor('settings-subscription:read') || !this.isUpsellingEnabledForAuthorization
      }
    }
  }
}
</script>
