<template>
  <v-card
    :style="cssProps"
    outlined
    :class="{ 'markero': isPlatformMarkero}"
  >
    <v-card-title :class="{ 'title': isPlatformMarkero}">
      {{ $t('reset-password.email-form.title') }}
    </v-card-title>

    <v-card-text :class="{ 'subtitle': isPlatformMarkero}">
      {{ $t('reset-password.email-form.subtitle') }}
    </v-card-text>

    <v-expand-transition>
      <v-alert
        v-if="error"
        type="error"
        dense
        tile
        text
      >
        {{ getErrorMessage(error) }}
      </v-alert>
    </v-expand-transition>

    <v-card-text>
      <v-form
        name="password-reset-email"
        @submit="submit"
      >
        <v-text-field
          v-model.trim="email"
          :label="$t('reset-password.email-form.input.label')"
          type="email"
          class="email"
          outlined
          prepend-inner-icon="mdi-email-outline"
        />

        <v-card-actions class="px-0">
          <v-btn
            v-if="isPlatformMarkero"
            class="link"
            outlined
            @click="$emit('back')"
          >
            {{ $t('buttons.reset-password.back') }}
          </v-btn>
          <router-link
            v-else
            :to="{ name: 'login' }"
          >
            {{ $t('reset-password.email-form.link') }}
          </router-link>
          <v-spacer />
          <loading-button
            :class="{ 'loading-btn': isPlatformMarkero }"
            color="primary"
            type="submit"
            :loading="submitting"
          >
            {{ $t('buttons.reset-password.submit') }}
          </loading-button>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import LoadingButton from '@/components/loading-button'
import { email } from '@/lib/validation'
import REQUEST_PASSWORD_RESET from '@/queries/RequestPasswordReset.gql'
import brandingMixin from '@/mixins/branding'

export default {
  components: {
    LoadingButton
  },
  mixins: [brandingMixin],
  data: () => ({
    email: '',
    error: null,
    submitting: false
  }),
  // request is without authtoken, therefore use other apollo client
  apollo: {
    $client: 'public'
  },
  computed: {
    cssProps () {
      return {
        '--markero-blue': this.$vuetify.theme.themes.light.markero.blue,
        '--markero-dark-grey': this.$vuetify.theme.themes.light.markero.darkGrey
      }
    },
    errorMessages () {
      return {
        'invalid-email': this.$t('reset-password.email-form.errors.invalid-email'),
        'empty-email': this.$t('reset-password.email-form.errors.empty-email'),
        'unknown-error': this.$t('reset-password.email-form.errors.unknown-error')
      }
    }
  },
  methods: {
    async submit (evt) {
      evt.preventDefault()

      this.error = null

      if (!this.email) {
        this.error = 'empty-email'
        return
      }

      if (email(this.email) !== true) {
        this.error = 'invalid-email'
        return
      }

      this.submitting = true
      try {
        const { data } = await this.$apollo.mutate({
          mutation: REQUEST_PASSWORD_RESET,
          variables: {
            input: {
              email: this.email
            }
          }
        })
        this.submitting = false

        const { errorCode } = data.sendResetOrVerificationMail

        // Pretend to send reset pw mail, when user was not found to avoid user sniffing
        if (errorCode && errorCode !== 'user-not-found') {
          this.error = errorCode
          return
        }

        this.$emit('submitted', { email: this.email })
      } catch (error) {
        this.error = 'unknown-error'
        this.submitting = false
      }

      this.submitting = false
    },
    getErrorMessage (code) {
      return this.errorMessages[code]
    }
  }
}
</script>

<style scoped>

.markero .title, .markero .subtitle, .markero .email, .markero .link, .markero .loading-btn {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
}

.markero .title, .markero .subtitle {
  color: #252525;
  text-align: center;
}

.markero .title {
  font-size: 28px !important;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 15px;
  display: block;
}

.markero .subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.markero .loading-btn {
  background-color: var(--markero-blue) !important;

}

.markero .loading-btn, .markero .link, .markero .email {
  border-radius: 10px;
}
.markero .email {
  color: var(--markero-dark-grey);
  width: 420px;
  height: 48px;
  gap: 20px;
  margin-bottom: 20px;
}

.markero .loading-btn, .markero .link {
  height: 53px;
  padding: 14px 34px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;

}

.markero .loading-btn {
  box-shadow: 5px 5px 15px 0 #2F16E840;
}

.markero .link {
  color: var(--markero-blue);
  background: #FFFFFF;
  border: 1px solid var(--markero-blue);
}

.markero .link:hover, .markero .loading-btn:hover {
  animation-duration: 0ms;
}

.markero.theme--light.v-sheet--outlined {
  border: none;
}

</style>
