<template>
  <div class="pa-5">
    <div class="design-wrapper">
      <Leaf
        class="design-elements"
        width="500"
        style="top:-700px; left: -200px; opacity: 0.15"
        fill
      />

      <Leaf
        class="design-elements"
        width="60"
        style="top:50px; left: 10; opacity: 0.8"
        stroke
        :stroke-width="1"
      />

      <Leaf
        class="design-elements"
        width="60"
        style="top:50px; left:20; opacity: 0.3"
        fill
      />
    </div>

    <div
      style="z-index: 2; position: relative"
    >
      <p
        class="text-h6 font-weight-regular"
        v-html="$t(title)"
      />

      <div class="justify-end d-flex">
        <div class="pl-16 mr-5 flex-grow-1 hidden-sm-and-down">
          <div class="justify-center d-flex">
            <div>
              <span class="font-weight text-body-1">{{ $t(subTitle) }}</span>
              <ul>
                <li
                  v-for="(item, i) in bulletPoints"
                  :key="i"
                >
                  <v-icon
                    color="primary"
                    class="mr-2"
                  >
                    mdi-check-circle
                  </v-icon>
                  <span class="font-weight text-body-1 ">{{ $t(item) }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          class="d-flex"
          :class="{'w-full':$vuetify.breakpoint.smAndDown}"
        >
          <div class="w-full flex-column d-flex">
            <div class="w-full mb-4 d-flex justify-space-between align-end">
              <div class="image-wrapper hidden-md-and-up">
                <v-img
                  class="rounded-lg prev-image"
                  :src="imgSrcBySlugAndVariant()"
                  max-width="100"
                  @click="imageDialog = true"
                />
                <v-icon
                  class="image-icon"
                  small
                >
                  mdi-magnify-plus
                </v-icon>
              </div>

              <p
                :style="{visibility: hasContract ? 'visible' : 'hidden'}"
                class="font-weight-regular text-h6 text-md-center ma-0 pa-0"
                :class="{'w-full':$vuetify.breakpoint.mdAndUp}"
              >
                {{ pricing }}
              </p>
            </div>

            <v-btn
              v-if="hasContract"
              :width="$vuetify.breakpoint.mdAndDown ? '100%': '350px' "
              color="primary"
              @click="submit"
            >
              {{ $t('buttons.upselling.cta') }}
            </v-btn>

            <a
              v-else
              :width="$vuetify.breakpoint.mdAndDown ? '100%': '350px' "
              target="_blank"
              href="https://termin.bottimmo.com"
            >
              <v-btn color="primary">
                {{ $t('buttons.upselling.support') }}
              </v-btn>
            </a>
          </div>
        </div>

        <div class="hidden-sm-and-down mt-n12">
          <div class="image-wrapper">
            <v-img
              class="rounded-lg prev-image ml-sm-4"
              :src="imgSrcBySlugAndVariant()"
              max-width="100"
              @click="imageDialog = true"
            />
            <v-icon
              class="image-icon"
              small
            >
              mdi-magnify-plus
            </v-icon>
          </div>
        </div>

        <v-dialog
          v-model="imageDialog"
          max-width="500"
        >
          <v-card
            :style="cssProps"
          >
            <div class="dialog-image-container">
              <v-img
                max-height="75vh"
                :src="imgSrcBySlugAndVariant()"
              />
              <div class="banner text-body-1 text-md-h6">
                {{ $t('upselling.banner.view') }}
              </div>
              <div class="overlay" />
            </div>

            <v-card-text>
              <v-btn
                v-if="hasContract"
                class="my-2"
                color="primary"
                width="100%"
                @click="submit"
              >
                {{ $t('buttons.upselling.cta') }}
              </v-btn>
              <a
                v-else
                class="my-2"
                target="_blank"
                href="https://termin.bottimmo.com"
              >
                <v-btn color="primary">
                  {{ $t('buttons.upselling.support') }}
                </v-btn>
              </a>
              <v-btn
                icon
                absolute
                top
                right
                @click="imageDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import UpsellingType from './enums/UpsellingType'
import Leaf from '@/components/design-elements/leaf.vue'
import featureMixin from '@/mixins/feature'

const TITLES = {
  [UpsellingType.GUIDE]: {
    default: 'upselling.banner.title.guide'
  },
  [UpsellingType.CHECKLIST]: {
    default: 'upselling.banner.title.checklist'
  },
  [UpsellingType.CAMPAIGN]: {
    default: 'upselling.banner.title.campaign-default',
    sicherheit: 'upselling.banner.title.campaign'
  },
  [UpsellingType.QUIZZARD]: {
    default: 'upselling.banner.title.quizzard'
  },
  [UpsellingType.LIFE_ANNUITY]: {
    default: 'upselling.banner.title.life-annuity'
  },
  [UpsellingType.PLOT_VALUATION]: {
    default: 'upselling.banner.title.plot-valuation'
  }
}

const SUBTITLES = {
  [UpsellingType.GUIDE]: {
    default: 'upselling.banner.subtitle.guide'
  },
  [UpsellingType.CHECKLIST]: {
    default: 'upselling.banner.subtitle.checklist'
  },
  [UpsellingType.CAMPAIGN]: {
    default: 'upselling.banner.subtitle.campaign'
  },
  [UpsellingType.QUIZZARD]: {
    default: 'upselling.banner.subtitle.quizzard'
  },
  [UpsellingType.LIFE_ANNUITY]: {
    default: 'upselling.banner.subtitle.life-annuity'
  },
  [UpsellingType.PLOT_VALUATION]: {
    default: 'upselling.banner.subtitle.plot-valuation'
  }
}

const BULLET_POINTS = {
  [UpsellingType.GUIDE]: {
    default: ['upselling.banner.bullet-points.guide.0', 'upselling.banner.bullet-points.guide.1', 'upselling.banner.bullet-points.guide.2']
  },
  [UpsellingType.CHECKLIST]: {
    default: ['upselling.banner.bullet-points.checklist.0', 'upselling.banner.bullet-points.checklist.1', 'upselling.banner.bullet-points.checklist.2']
  },
  [UpsellingType.CAMPAIGN]: {
    default: ['upselling.banner.bullet-points.campaign-default.0', 'upselling.banner.bullet-points.campaign-default.1', 'upselling.banner.bullet-points.campaign-default.2'],
    sicherheit: ['upselling.banner.bullet-points.campaign.0', 'upselling.banner.bullet-points.campaign.1', 'upselling.banner.bullet-points.campaign.2']
  },
  [UpsellingType.QUIZZARD]: {
    default: ['upselling.banner.bullet-points.quizzard.0', 'upselling.banner.bullet-points.quizzard.1', 'upselling.banner.bullet-points.quizzard.2']
  },
  [UpsellingType.LIFE_ANNUITY]: {
    default: ['upselling.banner.bullet-points.life-annuity.0', 'upselling.banner.bullet-points.life-annuity.1', 'upselling.banner.bullet-points.life-annuity.2']
  },
  [UpsellingType.PLOT_VALUATION]: {
    default: ['upselling.banner.bullet-points.plot-valuation.0', 'upselling.banner.bullet-points.plot-valuation.1', 'upselling.banner.bullet-points.plot-valuation.2']
  }
}

const PRICING = {
  [UpsellingType.GUIDE]: {
    default: 'upselling.banner.pricing.monthly'
  },
  [UpsellingType.CHECKLIST]: {
    default: 'upselling.banner.pricing.monthly'
  },
  [UpsellingType.CAMPAIGN]: {
    default: 'upselling.banner.pricing.once'
  },
  [UpsellingType.QUIZZARD]: {
    default: 'upselling.banner.pricing.monthly'
  },
  [UpsellingType.LIFE_ANNUITY]: {
    default: 'upselling.banner.pricing.monthly'
  },
  [UpsellingType.PLOT_VALUATION]: {
    default: 'upselling.banner.pricing.monthly'
  }
}

const IMAGE_BY_SLUG = {
  [UpsellingType.CHECKLIST]: {
    'aufbereitung-der-immobilie': 'BOTTIMMO-Checkliste-aufbereitung-der-immobilie_screen.jpg',
    energieausweis: 'BOTTIMMO-Checkliste-energieausweis_screen.jpg',
    'unterlagen-fuer-den-verkauf': 'BOTTIMMO-Checkliste-unterlagen-fuer-den-verkauf_screen.jpg',
    'was-gehoert-in-ein-expose': 'BOTTIMMO-Checkliste-was-gehoert-in-ein-expose_screen.jpg'
  },
  [UpsellingType.GUIDE]: {
    'immobilie-geerbt': 'BOTTIMMO-Ratgeber-immobilie-geerbt_screen.jpg',
    'immobilie-in-der-scheidung': 'BOTTIMMO-Ratgeber-immobilie-in-der-scheidung_screen.jpg',
    'immobilien-fakten-und-begriffe': 'BOTTIMMO-Ratgeber-immobilien-fakten-und-begriffe_screen.jpg',
    immobilienfinanzierung: 'BOTTIMMO-Ratgeber-immobilienfinanzierung_screen.jpg',
    leibrente: 'BOTTIMMO-Ratgeber-leibrente_screen.jpg',
    'privater-immobilienverkauf': 'BOTTIMMO-Ratgeber-privater-immobilienverkauf_screen.jpg',
    'richtiger-immobilienpreis': 'BOTTIMMO-Ratgeber-richtiger-immobilienpreis_screen.jpg',
    'sanierung-einer-immobilie': 'BOTTIMMO-Ratgeber-sanierung-einer-immobilie_screen.jpg',
    'wohnen-im-alter': 'BOTTIMMO-Ratgeber-wohnen-im-alter_screen.jpg'
  },
  [UpsellingType.CAMPAIGN]: {
    dschungel: 'Vorschau_Immobiliendschungel.jpg',
    lokalheld: 'Vorschau_Lokalheld.jpg',
    stadtland: 'Vorschau_Stadt-vs-Land.jpg',
    weihnachten: 'Vorschau_Weihnachten.jpg',
    willstdu: 'Vorschau_Willstdu.jpg',
    'immobilienbewertung-januar2022': 'Vorschau_Immobilienbewertung-Januar2022.jpeg',
    sicherheit: 'Vorschau_Sicherheit.jpg',
    grundsteueralarm: 'Vorschau_Grundsteueralarm.jpg',
    leibrente: 'Vorschau_Leibrente.jpg',
    machsmitmakler: 'Vorschau_MachsMitMakler.jpg',
    immoabc: 'Vorschau_ImmoABC.jpg',
    ostern2023: 'Vorschau_Ostern2023.jpg',
    energiekrise: 'Vorschau_Energiekrise.jpg',
    xmas: 'Vorschau_XMAS.jpg',
    neujahr: 'Vorschau_Neujahr.jpg',
    urlaubmalanders: 'Vorschau_UrlaubMalAnders.jpg',
    faqszuenergiethemen: 'Vorschau_FAQsZuEnergieThemen.jpg',
    sprueche: 'Vorschau_Sprueche.jpg',
    'ehe-aus': 'Vorschau_Ehe-Aus.jpg',
    nachbarn: 'Vorschau_Nachbarn.jpg',
    Ostern2022: 'Vorschau_Ostern2022.jpg',
    anschlussfinanzierung: 'Vorschau_Anschlussfinanzierung.jpg',
    weihnachten2023: 'Vorschau_Weihnachten2023.jpg',
    neujahr2024: 'Vorschau_Neujahr2024.jpg',
    nichtohne: 'Vorschau_NichtOhne.jpg'
  },
  [UpsellingType.QUIZZARD]: {
    sicherheit: 'QUIZZARD_Sicherheit.jpg'
  },
  [UpsellingType.LIFE_ANNUITY]: {
    'leibrenten-check': 'LIFE_ANNUITY.jpg'
  },
  [UpsellingType.PLOT_VALUATION]: {
    grundstuecksbewertung: 'PLOT_VALUATION.jpg'
  }
}

export default {
  name: 'Banner',

  components: { Leaf },

  mixins: [featureMixin],

  props: {
    type: { type: String, default: UpsellingType.LANDINGPAGE },
    price: { type: String, default: '' },
    submit: { type: Function, default: () => {} },
    slug: { type: String, default: '' },
    variant: { type: String, default: '' }
  },

  data () {
    return {
      imageDialog: false
    }
  },

  computed: {
    hasContract () {
      return this.isFeatureActive(this.featureNames.CONTRACT)
    },
    title () {
      return TITLES[this.type][this.slug.toLowerCase()] || TITLES[this.type].default
    },
    subTitle () {
      return SUBTITLES[this.type][this.slug.toLowerCase()] || SUBTITLES[this.type].default
    },
    bulletPoints () {
      return BULLET_POINTS[this.type][this.slug.toLowerCase()] || BULLET_POINTS[this.type].default
    },
    pricing () {
      return `${this.$t(PRICING[this.type][this.slug.toLowerCase()]) || this.$t(PRICING[this.type].default)} ${this.price}`
    },
    cssProps () {
      return {
        '--warning-color': this.$vuetify.theme.themes.light.warning
      }
    }
  },

  methods: {
    imgSrcBySlugAndVariant (preview = false) {
      const image = IMAGE_BY_SLUG[this.type][this.slug.toLowerCase()]
      if (!image) {
        // @todo replace with custom static image
        return `https://via.placeholder.com/${preview ? '150x212' : '300x425'}/FFFFFF/000000/?text=${this.slug}`
      }
      return `https://static.bottimmo.show/images/covers/${this.variant.toUpperCase()}/${image}?w=${preview ? '150' : '600'}`
    }
  }
}
</script>

<style scoped>
.design-wrapper {
  position: relative;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}

.background-image{
  position: absolute;
  bottom: 0;
  left: 30px;
  height: 100px;
}

.bg-purple {
    color: #fff;
}

.prev-image {
  cursor: pointer;
}

.image-wrapper {
  position: relative;
}
.image-icon {
  position: absolute;
  bottom: 18px;
  right: 4px;
}

@media (min-width: 600px) {
  .image-icon {
    bottom: 4px;
    right: 4px;
  }

  .banner{
    top:50px;
    left: -70px;
  }
}

.dialog-image-container{
  position:relative;
}

.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(210deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 100%);
}

.banner {
    position: absolute;
    top:40px;
    left: -90px;
    background-color: var(--warning-color) ;
    color:white;
    text-align:center;
    width:300px;
    height:40px;
    line-height:40px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
</style>
