<template>
  <div>
    <Filters
      v-if="showDialog"
      :topics="topicItems"
      :kinds="availableKinds"
      :filter="filter"
      @close="showDialog = false"
      @applyFilters="applyFilters"
    />
    <div class="d-md-flex justify-md-space-between">
      <v-btn
        class="my-2 ma-sm-2 w-xs-full"
        color="primary"
        text
        outlined
        :disabled="isContentTypeDisabled"
        @click="openFiltersDialog"
      >
        <v-icon
          left
          x-small
        >
          mdi mdi-tune-variant
        </v-icon>

        {{ $t('buttons.content-creator.filters') }}
        <v-badge
          v-if="filter.numberOfAppliedFilters"
          color="red"
          :content="filter.numberOfAppliedFilters"
          offset-y="-2"
          offset-x="-2"
        >
          <v-icon
            icon="$vuetify"
            size="x-large"
          />
        </v-badge>
      </v-btn>
      <div class="justify-space-between d-none d-sm-flex">
        <Tabs
          :section="section"
          :disabled="isContentTypeDisabled"
          @sectionChange="onSectionChange($event)"
          @refetch="refetchSavedContents"
        />
      </div>
    </div>
    <v-divider class=" divider" />

    <info-box
      v-if="!isContentTypeDisabled && contents.length === 0 && !isGenerating"
      :info-message="infoMessage"
    />

    <v-tabs-items
      v-model="section"
      touchless
    >
      <SavedContentTab
        v-for="filterValue in filterValues"
        :key="filterValue"
        :content-type="contentType"
        :disabled="isContentTypeDisabled"
        :filter-value="filterValue"
        :saved-contents="contents"
        @contentSaved="refetchSavedContents"
        @savedScheduledContent="refetchSavedContents"
      />
    </v-tabs-items>

    <div
      v-if="contents.length > 0"
      class="text-center mt-10 mb-5"
    >
      <v-pagination
        v-model="currentPage"
        :disabled="disabled"
        :length="paginationLength"
        :total-visible="7"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="updatePage"
      />
    </div>

    <ProgressCircular v-if="isGenerating" />
  </div>
</template>

<script>
import GET_ACTIVE_TOPICS from './queries/getActiveTopics.gql'
import GET_SAVED_CONTENTS from '@/modules/contentCreator/creator/queries/getSavedContent.gql'
import GET_SCHEDULED_CONTENTS from '@/modules/contentCreator/creator/queries/getScheduledContents.gql'
import KindType, { KindTypeIcon } from '@/modules/contentCreator/creator/enums/KindType'
import { FilterType } from '@/modules/contentCreator/creator/enums/FilterType'
import Filters from '@/modules/contentCreator/creator/Filters.vue'
import { textTruncate } from '@/lib/truncate'
import ProgressCircular from '@/components/ProgressCircular.vue'
import SavedContentTab from '@/modules/contentCreator/creator/SavedContentsTab.vue'
import InfoBox from '@/components/InfoBox.vue'
import feature from '@/mixins/feature'
import { transformToKebabCase } from '@/lib/kebabCase'
import { ContentType } from '@/modules/contentCreator/creator/enums/ContentType'
import Tabs from '@/modules/contentCreator/creator/Tabs.vue'

export default {
  components: { Tabs, SavedContentTab, ProgressCircular, Filters, InfoBox },
  mixins: [feature],
  props: {
    updateSavedContents: {
      type: Number,
      default: () => 0
    },
    contentType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filter: {
        selectedKinds: [],
        selectedTopics: [],
        numberOfAppliedFilters: 0
      },
      savedContents: {
        total: 0,
        savedContents: []
      },
      scheduledContents: {
        total: 0,
        scheduledContents: []
      },
      section: FilterType.ALL,
      showDialog: false,
      currentPage: 1,
      currentHash: ''
    }
  },
  computed: {
    filterValues () {
      return [
        this.FilterType.ALL,
        this.FilterType.TODAY,
        this.FilterType.WEEK,
        this.FilterType.MONTH,
        this.FilterType.YEAR
      ]
    },
    paginationLength () {
      const total = this.contentType === this.ContentType.SAVED_CONTENT
        ? this.savedContents.total
        : this.scheduledContents.total
      return Math.ceil(total / 20)
    },
    infoMessage () {
      return this.contentType === this.ContentType.SAVED_CONTENT
        ? this.$t('alerts.content-creator.save-content.info')
        : this.$t('alerts.content-creator.scheduled-content.info')
    },
    contents () {
      return this.contentType === ContentType.SAVED_CONTENT
        ? this.savedContents.savedContents
        : this.scheduledContents.scheduledContents
    },
    ContentType () {
      return ContentType
    },
    FilterType () {
      return FilterType
    },
    availableKinds () {
      return Object.keys(KindType).map(key => {
        return {
          value: key,
          text: this.$t(`content-creator.text-kind-types.${this.transformToKebabCase(key)}`),
          icon: KindTypeIcon[key]
        }
      })
    },
    isGenerating () {
      return this.$apollo.loading
    },
    topicItems () {
      return this.topics
        .map((topic) => {
          return {
            value: topic.id,
            text: topic.name,
            type: topic.landingpage ? topic.landingpage.type : 'TOPIC'
          }
        })
        .reduce((acc, { type, ...rest }) => {
          if (type in acc) {
            acc[type].items.push(rest)
          } else {
            acc.TOOL.items.push({ type, ...rest })
          }
          return acc
        }, {
          TOPIC: { items: [], header: 'Topics' },
          TOOL: { items: [], header: 'Tools' },
          GUIDE: { items: [], header: 'Ratgeber' },
          CHECKLIST: { items: [], header: 'Checklisten' }
        })
    },
    isContentTypeDisabled () {
      const featureConfig = this.getFeature(this.featureNames.CONTENT_CREATOR)?.config
      if (!featureConfig) return true
      const options = {
        [this.ContentType.SAVED_CONTENT]: featureConfig.isHistoryEnabled,
        [this.ContentType.SCHEDULED_CONTENT]: featureConfig.schedulePost
      }
      return !options[this.contentType]
    }
  },
  watch: {
    updateSavedContents () {
      this.refetchSavedContents()
    }
  },
  methods: {
    transformToKebabCase,
    onSectionChange (section) {
      this.section = section
    },
    refetchSavedContents () {
      this.resetCurrentPage()

      if (this.contentType === ContentType.SAVED_CONTENT) {
        this.$apollo.queries.savedContents.refetch()
      } else {
        this.$apollo.queries.scheduledContents.refetch()
      }
    },
    resetCurrentPage () {
      this.currentPage = 1
    },
    updatePage (currentPage) {
      this.currentPage = currentPage
    },
    textTruncate,
    openFiltersDialog () {
      this.showDialog = true
    },
    applyFilters (filter) {
      this.filter = filter
    }
  },
  apollo: {
    topics: {
      query: GET_ACTIVE_TOPICS
    },
    savedContents: {
      query: GET_SAVED_CONTENTS,
      fetchPolicy: 'no-cache',
      variables () {
        return {
          input: {
            timeRange: this.section,
            textKinds: this.filter.selectedKinds,
            topics: this.filter.selectedTopics,
            page: this.isContentTypeDisabled ? 1 : this.currentPage,
            ...(this.isContentTypeDisabled && { perPage: 3 })
          }
        }
      },
      skip () {
        return this.contentType === ContentType.SCHEDULED_CONTENT
      }
    },
    scheduledContents: {
      query: GET_SCHEDULED_CONTENTS,
      fetchPolicy: 'no-cache',
      variables () {
        return {
          input: {
            timeRange: this.section,
            textKinds: this.filter.selectedKinds,
            topics: this.filter.selectedTopics,
            page: this.isContentTypeDisabled ? 1 : this.currentPage,
            ...(this.isContentTypeDisabled && { perPage: 3 })
          }
        }
      },
      skip () {
        return this.contentType === ContentType.SAVED_CONTENT
      }
    }
  }
}
</script>

<style scoped>
  .divider {
    border-color: #F1F1F4;
  }
</style>
