<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
          <span class="add-account">{{ $t('labels.add-account') }}</span>
        </v-btn>
      </template>

      <v-card class="d-flex flex-column">
        <v-card-title class="pa-0">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title class="link-accounts-title">
              {{ $t('social-media.link-accounts') }}
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="close"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text v-if="connectingTo">
          <ProgressCircular />
          <div class="text-center connected-pages">
            {{ $t('alerts.social-media.connecting-to-social-media.info', {platform: connectingTo}) }}
          </div>
        </v-card-text>

        <v-card-text
          v-else
          class="mt-5 d-flex flex-column gap-1"
        >
          <template v-if="!allPlatformsConnected">
            <div class="connected-pages">
              {{ $t('social-media.choose-the-platform-to-connect-and-post', {readablePlatformName}) }}
            </div>
            <BlurredBackdropUpselling
              :is-blurred="!postToFacebook"
              margin-top="80px"
              min-height="180px"
            >
              <div
                v-if="metaPages.length === 0"
                class="w-full"
              >
                <ConnectFacebook
                  class="w-full"
                  :connection-wrapper="connectionWrapper"
                  :feature-config="featureConfig"
                  @featureEnabled="postToFacebook = $event"
                />
                <v-alert
                  v-if="error.Facebook"
                  type="error"
                  dense
                  tile
                  text
                  class="mb-0 newline"
                >
                  <div v-html="$t('alerts.social-media.no-pages-found.facebook', { link: '<a target=\'_blank\' href=\'https://www.facebook.com/pages/creation/\'>Facebook Pages</a>' })" />
                </v-alert>
              </div>
            </BlurredBackdropUpselling>

            <BlurredBackdropUpselling
              :is-blurred="!postToInstagram"
              margin-top="120px"
              min-height="260px"
            >
              <ConnectInstagram
                v-if="!socialMediaConnections.includes('INSTAGRAM')"
                :is-facebook-connected="socialMediaConnections.includes('FACEBOOK')"
                :connection-wrapper="connectionWrapper"
                :feature-config="featureConfig"
                @featureEnabled="postToInstagram = $event"
              />
              <v-alert
                v-if="error.Instagram"
                type="error"
                dense
                tile
                text
                class="mb-0 newline"
              >
                <div v-html="$t('alerts.social-media.no-pages-found.instagram', { businessSuiteLink: '<a target=\'_blank\' href=\'https://business.facebook.com/latest/settings/instagram_account?selected_asset_type=instagram-account-v2&detail_view_tab=CONNECTED_ASSETS\'>Meta-Business-Suite</a>' })" />
              </v-alert>
            </BlurredBackdropUpselling>

            <BlurredBackdropUpselling
              :is-blurred="!postToLinkedin"
              margin-top="80px"
              min-height="180px"
            >
              <div
                v-if="linkedinPages.length === 0"
                class="w-full"
              >
                <ConnectLinkedIn
                  class="w-full"
                  :connection-wrapper="connectionWrapper"
                  :feature-config="featureConfig"
                  @featureEnabled="postToLinkedin = $event"
                />
                <v-alert
                  v-if="error.LinkedIn"
                  type="error"
                  dense
                  tile
                  text
                  class="mb-0 newline"
                >
                  <div v-html="$t('alerts.social-media.no-pages-found.linkedin', { link: '<a target=\'_blank\' href=\'https://www.linkedin.com/company/setup/new/\'>LinkedIn Pages</a>' })" />
                </v-alert>
              </div>
            </BlurredBackdropUpselling>
          </template>
          <div
            v-if="socialMediaConnections.length > 0"
            class="connected-pages"
          >
            {{ $t('social-media.choose-the-platform-connected-pages') }}:
          </div>
          <SocialMediaPageSelection
            v-if="metaPages.length"
            :pages="metaPages"
            platform="FACEBOOK"
          />
          <SocialMediaPageSelection
            v-if="instagramPages.length"
            :pages="instagramPages"
            platform="INSTAGRAM"
          />
          <SocialMediaPageSelection
            v-if="linkedinPages.length"
            :pages="linkedinPages"
            platform="LINKEDIN"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            class="close-btn"
            @click="close"
          >
            {{ $t('labels.close') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            class="proceed-btn"
            text
            @click="close"
          >
            {{ $t('labels.proceed') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import branding from '@/mixins/branding'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import ProgressCircular from '@/components/ProgressCircular.vue'
import ConnectLinkedIn from './ConnectLinkedIn.vue'
import ConnectFacebook from './ConnectFacebook.vue'
import ConnectInstagram from './ConnectInstagram.vue'
import SocialMediaPageSelection from './SocialMediaPageSelection.vue'

import GET_SOCIAL_MEDIA_CONNECTIONS from '../../queries/getSocialMediaConnections.gql'
import GET_LINKEDIN_PAGES from '../../queries/getLinkedinOrganizations.gql'
import GET_FACEBOOK_PAGES from '../../queries/getFacebookPages.gql'
import CONNECT_TO_SOCIAL_MEDIA from '../../queries/connectSocialMedia.gql'
import { SocialMedia } from '../../enums/SocialMedia'
import feature from '@/mixins/feature'
import BlurredBackdropUpselling from '@/components/BlurredBackdropUpselling.vue'

export default {
  components: {
    ProgressCircular,
    SocialMediaPageSelection,
    BlurredBackdropUpselling,
    ConnectLinkedIn,
    ConnectFacebook,
    ConnectInstagram
  },
  mixins: [branding, feature],
  props: {
    connectMediaDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      socialMediaConnections: [],
      linkedinPages: [],
      metaPages: [],
      dialog: false,
      connectingTo: null,
      featureConfig: null,
      postToFacebook: true,
      postToInstagram: true,
      postToLinkedin: true,
      error: {}
    }
  },
  computed: {
    allPlatformsConnected () {
      return this.socialMediaConnections.length === 3
    },
    instagramPages () {
      return this.metaPages
        .filter(page => this.socialMediaConnections.includes('INSTAGRAM') && page.instagram_business_account)
        .map(({ instagram_business_account: { username, id } }) => ({
          name: username,
          id,
          isActive: true
        }))
    }
  },
  watch: {
    connectMediaDialog (val) {
      this.dialog = val
    }
  },
  async created () {
    const { config } = await this.getFeature(this.featureNames.CONTENT_CREATOR)
    this.featureConfig = config
  },
  methods: {
    close () {
      this.dialog = false
      this.error = {}

      if (this.connectMediaDialog) {
        this.$emit('close')
      }
    },
    async connectionWrapper (platform, connect) {
      try {
        this.error[platform] = null
        this.connectingTo = platform
        const { accessToken, userId, type, redirectUri } = await connect()
        await this.connectToSocialMedia({ accessToken, userId, type, redirectUri })
        this.$tracking.event('Content Creator', 'Connected', platform)
        showSnackbarMessage('success', this.$t('alerts.social-media.connect-to-platform.success', { platform }))
      } catch (err) {
        if (err.graphQLErrors?.[0]?.extensions?.exception?.code === 'NO_PAGES') {
          this.error[platform] = true
        } else {
          showSnackbarMessage('error', this.$t('alerts.social-media.connect-to-platform.error', { platform }))
        }
      } finally {
        this.connectingTo = null
      }
    },
    async connectToSocialMedia ({ accessToken, userId, type, redirectUri }) {
      const refetchQueries = {
        [SocialMedia.LINKEDIN]: [{ query: GET_LINKEDIN_PAGES }, { query: GET_SOCIAL_MEDIA_CONNECTIONS }],
        [SocialMedia.FACEBOOK]: [{ query: GET_FACEBOOK_PAGES }, { query: GET_SOCIAL_MEDIA_CONNECTIONS }],
        [SocialMedia.INSTAGRAM]: [{ query: GET_SOCIAL_MEDIA_CONNECTIONS }]
      }[type]
      await this.$apollo.mutate({
        mutation: CONNECT_TO_SOCIAL_MEDIA,
        variables: {
          input: {
            type,
            accessToken,
            userId,
            redirectUri
          }
        },
        refetchQueries
      })
    }
  },
  apollo: {
    socialMediaConnections: {
      query: GET_SOCIAL_MEDIA_CONNECTIONS
    },
    linkedinPages: {
      query: GET_LINKEDIN_PAGES,
      skip () {
        return !this.socialMediaConnections.includes('LINKEDIN')
      }
    },
    metaPages: {
      query: GET_FACEBOOK_PAGES,
      skip () {
        return !this.socialMediaConnections.includes('FACEBOOK')
      }
    }
  }
}
</script>

<style scoped>
.link-accounts-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
.add-account {
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.connected-pages {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.proceed-btn, .close-btn {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
</style>
