<template>
  <v-card
    v-if="!verified"
    outlined
    class="mb-4 header mt-md-4"
  >
    <v-card-title>
      <v-row>
        <v-col
          cols="10"
        >
          <v-icon
            class="mr-2"
            color="black"
          >
            mdi-email-outline
          </v-icon>
          <span style="word-break: break-word;">
            {{ $t('user-mail-verification.title') }}
          </span>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-end"
        >
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                small
                fab
                :loading="isLoading"
                :disabled="isLoading"
                v-bind="attrs"
                v-on="on"
                @click="checkStatus"
              >
                <v-icon alt="Aktualisieren">
                  mdi-reload
                </v-icon>
              </v-btn>
            </template>
            <span>Aktualisieren</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          {{ $t('user-mail-verification.body-texts.0', { email: email, platform: readablePlatformName }) }}<br>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-end"
        >
          <v-btn
            :color="isPlatformMarkero ? 'primary' : 'success'"
            text
            :loading="isLoading"
            :disabled="isLoading"
            @click="sendVerifyEmailMail"
          >
            {{ $t('buttons.user-mail-verification.cta') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import eventBus, { eventNames } from '@/lib/eventBus'
import SEND_VERIFY_EMAIL_MAIL from './SendVerifyEmailMail.gql'
import brandingMixin from '@/mixins/branding'

export default {
  mixins: [brandingMixin],
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    email () {
      return this.$auth.user.email
    },
    verified () {
      return this.$auth.user.email_verified
    }
  },
  methods: {
    async checkStatus () {
      await this.$auth.getTokenSilently({
        cacheMode: 'off'
      })
    },
    async sendVerifyEmailMail () {
      this.isLoading = true
      try {
        await this.$apollo.mutate({ mutation: SEND_VERIFY_EMAIL_MAIL })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.user-mail-verification.success', { email: this.email }) })
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.user-mail-verification.error') })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
