<template>
  <v-card class="pb-3 elevation-0">
    <EditContent
      v-if="showEditContentDialog"
      :content="selectedContent"
      @update="updateText"
      @close="closeEditDialog"
    />
    <v-row class="d-flex">
      <v-col
        cols="12"
        sm="6"
        md="8"
        class="py-4"
      >
        <v-row style="max-width: 1000px;">
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="kind"
              :items="availableKinds"
              outlined
              hide-details
              :disabled="isAnyPartLoading"
              dense
              :label="$t('buttons.content-creator.text-kind')"
              :menu-props="kindMenuProps"
              class="pointer"
              item-text="text"
              item-value="value"
              @change="handleKindChange(kind)"
              @click="handleKindClick()"
            >
              <template v-slot:selection="data">
                <div class="text-truncate selection-label">
                  <v-avatar
                    left
                    size="20"
                  >
                    <v-img :src="data.item.icon" />
                  </v-avatar>
                  <span class="ml-3">{{ data.item.text }}</span>
                </div>
              </template>

              <template v-slot:item="{item, on, attrs}">
                <v-list-item
                  v-bind="attrs"
                  v-on="!item.needsUpgrade && on"
                  @click="item.needsUpgrade && goToUpgrade()"
                >
                  <v-list-item-avatar size="20">
                    <v-img :src="item.icon" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>

                    <v-list-item-subtitle
                      v-if="item.needsUpgrade"
                      class="needs-upgrade-subtitle"
                    >
                      {{ $t('content-creator.text-kind-upgrade') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-avatar v-if="item.needsUpgrade">
                    <v-icon>
                      mdi-one-up
                    </v-icon>
                  </v-list-item-avatar>
                </v-list-item>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="topicId"
              :items="topicSelectItems"
              outlined
              hide-details
              :disabled="isAnyPartLoading"
              dense
              :label="$t('buttons.content-creator.topic')"
              class="pointer"
              @change="handleTopicChange()"
              @click="handleTopicClick()"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-btn
              class="w-full generate-btn"
              color="primary"
              elevation="0"
              :disabled="isAnyPartLoading || !kind || !topic"
              @click="generate"
            >
              <v-icon
                left
                dark
              >
                mdi-refresh
              </v-icon>
              {{ $t('buttons.content-creator.generate-content') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        v-if="imageSources.length > 1"
        cols="12"
        sm="6"
        md="3"
        lg="2"
        offset-md="1"
        offset-lg="2"
        class="pt-4"
      >
        <v-tooltip
          :disabled="hasTextKindImage"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <v-select
                v-model="imageSource"
                outlined
                dense
                hide-details
                :label="$t('buttons.content-creator.image-source')"
                validate-on-blur
                :items="imageSourceItems"
                :disabled="isAnyPartLoading || !hasTextKindImage"
                @change="handleImageSourceChange()"
                @click="handleImageSourceClick()"
              />
            </div>
          </template>
          <span>{{ $t('content-creator.tooltip.no-image-for-text-kind') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <template v-if="hasTopics">
          <div ref="creator">
            <GeneratedContent
              :kind="kind"
              :has-image="hasImage"
              :generated-content="generatedContent"
              :loading-state="loadingState"
              @openEditTextDialog="openEditTextDialog"
              @change="handleChange"
            />
          </div>
          <div class="w-full mt-2 d-md-flex justify-md-center align-md-center mt-sm-5">
            <DownloadPost
              btn-class="my-2 w-xs-full"
              :save-content="true"
              :text-kind="kind"
              :image="selectedContent?.image?.url"
              :image-source="imageSource"
              :text="selectedContent?.text"
              :topic="topic?.name"
              :topic-id="topic?.id"
              :url="topic?.landingpage?.url"
              :disabled="isLoadingOrPostNotComplete"
              :primary="false"
              @contentSaved="$emit('contentSaved')"
            />
            <SocialMediaSharing
              :content="{
                text: selectedContent.text,
                image: selectedContent.image?.url,
                imageSource,
                textKind: kind,
                topicId: topic?.id,
                socialMediaPosts: []
              }"
              :disabled="isLoadingOrPostNotComplete"
              @contentSaved="$emit('contentSaved')"
              @savedScheduledContent="$emit('savedScheduledContent')"
            />
          </div>
          <GenerationLimits />
        </template>

        <template v-else-if="!isLoadingTopics">
          <info-box
            :info-message="infoMessages.generateContent"
            :link-message="infoMessages.generateContentLink"
            :link-path="'/topics'"
          />
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import lightMaterials from '@/lib/lightMaterials'
import KindType, { KindTypeIcon } from './enums/KindType'
import GET_ACTIVE_TOPICS from './queries/getActiveTopics.gql'
import GENERATE_TEXT from './queries/generateText.gql'
import GET_LAST_GENERATED_TEXT from './queries/getLastGeneratedText.gql'
import GET_MATERIALS from './queries/getMaterials.gql'
import GET_STOCK_PHOTOS from './queries/getStockPhotos.gql'
import GET_GENERATED_IMAGES from './queries/getGeneratedImages.gql'
import GET_USER_IMAGES from './queries/getUserImages.gql'
import UPLOAD_USER_IMAGE from './queries/uploadUserImage.gql'
import GENERATE_IMAGE from './queries/generateImage.gql'
import GeneratedContent from './GeneratedContent.vue'
import { partMapper } from './lib/partMapper'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import featureMixin from '@/mixins/feature'
import brandingMixin from '@/mixins/branding'
import InfoBox from '@/components/InfoBox.vue'
import EditContent from '@/modules/contentCreator/creator/EditContent.vue'
import setQueryParameters from '@/lib/setQueryParameters'
import DownloadPost from './DownloadPost.vue'
import { buildUrlWithTracking } from '@/modules/contentCreator/creator/lib/urlWithTracking'
import GenerationLimits from './GenerationLimits.vue'
import SocialMediaSharing from './SocialMedia/SocialMediaSharing.vue'
import { FilterType } from '@/modules/contentCreator/creator/enums/FilterType'
import { transformToKebabCase } from '@/lib/kebabCase'
import COMPANY from '@/modules/settings/companySettings/queries/CompanyData.gql'

export default {
  components: {
    EditContent,
    GeneratedContent,
    InfoBox,
    DownloadPost,
    GenerationLimits,
    SocialMediaSharing
  },
  mixins: [featureMixin, brandingMixin],
  props: {
    activeTab: {
      type: String,
      default: FilterType.ALL
    }
  },
  data () {
    return {
      loadingState: {
        image: false,
        text: false
      },
      KindType,
      imageSource: null,
      imageSources: [],
      stockPhotos: {
        page: 1,
        perPage: 20
      },
      enabledTextKinds: [],
      kind: KindType.facebookPost,
      topics: [],
      topicId: this.$route.query.topicId || '',
      generatedContent: {
        image: {},
        text: {
          // Let's set an empty string as default so there is always at least one slide in the carousel for showing the loading spinner
          choices: ['']
        }
      },
      selectedContent: {
        image: {},
        text: ''
      },
      showSocialMediaDialog: false,
      showEditContentDialog: false,
      editTextDialogIndex: null,
      enabledPostingChannels: {}
    }
  },
  computed: {
    isAnyPartLoading () {
      return Object.values(this.loadingState).some(Boolean)
    },
    topicSelectItems () {
      const categories = this.topics
        .map((topic) => {
          return {
            value: topic.id,
            text: topic.name,
            type: topic.landingpage ? topic.landingpage.type : 'TOPIC'
          }
        })
        .reduce((acc, { type, ...rest }) => {
          if (type in acc) {
            acc[type].items.push(rest)
          } else {
            acc.TOOL.items.push({ type, ...rest })
          }
          return acc
        }, {
          TOPIC: { items: [], header: this.$t('labels.content-creator.topics') },
          TOOL: { items: [], header: this.$t('labels.content-creator.tools') },
          GUIDE: { items: [], header: this.$t('labels.content-creator.guides') },
          CHECKLIST: { items: [], header: this.$t('labels.content-creator.checklists') }
        })

      const slugs = []
      Object.values(categories).forEach(({ items, header }, index) => {
        if (items.length > 0) {
          if (index !== 0) {
            slugs.push({ divider: true })
          }
          slugs.push({ header }, ...items)
        }
      })
      return slugs
    },
    imageSourceItems () {
      return this.imageSources.map(source => ({
        text: this.$t('content-creator.image-sources.' + source, { platform: this.readablePlatformName }),
        value: source
      }))
    },
    topic () {
      return this.topics.find(({ id }) => id === this.topicId) || {}
    },
    infoMessages () {
      return {
        generateContent: this.$t('alerts.content-creator.generate-content.info'),
        generateContentLink: this.$t('alerts.content-creator.generate-content.link'),
        publishPostExample: this.$t('alerts.content-creator.social-media.info')
      }
    },
    availableKinds () {
      return Object.keys(KindType)
        .map(key => ({
          value: key,
          text: this.$t(`content-creator.text-kind-types.${this.transformToKebabCase(key)}`),
          icon: KindTypeIcon[key],
          needsUpgrade: !(this.enabledTextKinds === 'all' || this.enabledTextKinds.includes(key))
        }))
    },
    hasTextKindImage () {
      return partMapper[this.kind].includes('image')
    },
    hasImage () {
      return this.generatedContent?.image?.choices?.length > 0
    },
    hasText () {
      return this.generatedContent?.text?.choices?.length > 0
    },
    isLoadingOrPostNotComplete () {
      return this.isAnyPartLoading || (partMapper[this.kind].includes('text') && !this.selectedContent?.text) ||
        (partMapper[this.kind].includes('image') && !this.selectedContent?.image?.url)
    },
    isLoadingTopics () {
      return this.$apollo.queries.topics.loading
    },
    hasTopics () {
      return this.topics.length > 0
    },
    kindMenuProps () {
      if (this.$vuetify.breakpoint.smAndUp) {
        return { maxWidth: 400 }
      }
      return {}
    }
  },
  watch: {
    async imageSource () {
      // Need to update the link with different image source
      this.generatedContent.text.choices = this.generatedContent.text?.choices?.map(text => this.updateLinkInText(text))
      await this.getImages()
    },
    async kind () {
      this.resetGeneratedContent('text')
    },
    async topicId () {
      this.resetGeneratedContent('text')
      this.resetGeneratedContent('image')
      await this.getImages()
    },
    hasTopics (hasTopics) {
      if (hasTopics) {
        this.ensureTopicId()
      }
    }
  },
  async created () {
    const feature = await this.getFeature(this.featureNames.CONTENT_CREATOR)
    const config = feature.config

    this.enabledTextKinds = feature.config.enabledTextKinds
    this.imageSources = config.imageSources
    this.imageSource = config.imageSources[0]
    this.stockPhotos.limit = config.stockImageLimit

    this.enabledPostingChannels = {
      [KindType.facebookPost]: config.postToFacebook,
      [KindType.instagramPost]: config.postToInstagram,
      [KindType.linkedinPost]: config.postToLinkedin
    }

    if (this.isCallbackAfterLinkedinAuth()) {
      this.openSocialMediaDialog()
    }
  },
  methods: {
    transformToKebabCase,
    /**
     * If no topicId is provided (e.g. from query parameter)
     * we need to ensure that the first topic is selected.
     */
    ensureTopicId () {
      if (!this.topicId && this.hasTopics) {
        this.topicId = this.topics[0].id
      }
    },
    handleKindChange (kind) {
      this.$tracking.event('Content Creator', 'Selected', 'Textkind', kind)
    },
    handleKindClick () {
      this.$tracking.event('Content Creator', 'Clicked', 'Textkind')
    },
    handleTopicChange () {
      this.$tracking.event('Content Creator', 'Selected', 'Topic', this.topic.name)
    },
    handleTopicClick () {
      this.$tracking.event('Content Creator', 'Clicked', 'Topic')
    },
    handleImageSourceChange () {
      this.$tracking.event('Content Creator', 'Selected', 'ImageSource')
    },
    handleImageSourceClick () {
      this.$tracking.event('Content Creator', 'Clicked', 'ImageSource')
    },
    isCallbackAfterLinkedinAuth () {
      return this.$route.query.linkedinAuthCallback
    },

    startLoading (part) {
      this.loadingState[part] = true
    },

    stopLoading (part) {
      this.loadingState[part] = false
    },

    closeConnectDialog () {
      this.showSocialMediaDialog = false
    },
    closeEditDialog () {
      this.editTextDialogIndex = null
      this.showEditContentDialog = false
    },

    resetGeneratedContent (part = null) {
      if (part === 'text') {
        // Let's set an empty string as default so there is always at least one slide in the carousel for showing the loading spinner
        this.generatedContent.text = { choices: [''] }
        this.setDefaultSelectedText()
      } else if (part === 'image') {
        this.generatedContent.image = {}
        this.setDefaultSelectedImage()
      } else {
        this.generatedContent = {
          image: {},
          text: { choices: [''] }
        }
      }
    },

    updateText (text) {
      this.generatedContent.text.choices = [
        ...this.generatedContent.text.choices.slice(0, this.editTextDialogIndex),
        text,
        ...this.generatedContent.text.choices.slice(this.editTextDialogIndex + 1)
      ]
      this.selectedContent.text = text
    },
    openEditTextDialog (index) {
      this.showEditContentDialog = true
      this.editTextDialogIndex = index
    },
    handleChange (data) {
      this.selectedContent[data.part] = data.content
    },
    setDefaultSelectedImage () {
      this.selectedContent.image = this.generatedContent?.image?.choices?.[0]
    },
    setDefaultSelectedText () {
      this.selectedContent.text = this.generatedContent?.text?.choices?.[0]
    },
    // TODO: this won't work anymore if we allow to disable hashtags.
    // Instead link should be added through AI genereration.
    addLinkToText (text) {
      if ([KindType.facebookPost, KindType.linkedinPost].includes(this.kind) && this.topic?.landingpage?.url) {
        const url = this.topic?.landingpage?.url
        const hashtagIndex = text.indexOf('#')
        if (hashtagIndex === -1) {
          return (text + '\n\n' + buildUrlWithTracking(url, this.kind, this.imageSource))
        }

        return (text.slice(0, hashtagIndex) + buildUrlWithTracking(url, this.kind, this.imageSource) + '\n\n' + text.slice(hashtagIndex))
      }

      return text
    },
    updateLinkInText (text) {
      const url = this.topic?.landingpage?.url
      const regex = new RegExp(`${url}\\?utm_source=.*&utm_medium=.*&utm_content=.*`)
      return text.replace(regex, buildUrlWithTracking(url, this.kind, this.imageSource))
    },
    async generate () {
      const prevText = this.generatedContent.text

      this.startLoading('text')
      this.resetGeneratedContent('text')

      let choices = await this.generateText()
      if (!choices) {
        choices = prevText.choices
      }

      this.generatedContent.text = { choices }
      this.setDefaultSelectedText()
      this.stopLoading('text')

      this.$tracking.event('Content Creator', 'Clicked', 'Generation')
    },
    async generateText () {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: GENERATE_TEXT,
          variables: {
            input: {
              topicId: this.topicId,
              textKind: this.kind
            }
          },
          refetchQueries: ['getGenerationStats']
        })

        if (data?.generatedText?.choices?.length > 0) {
          return data.generatedText.choices.map(text => this.addLinkToText(text))
        }

        this.$tracking.event('Textual Content', 'Generated', this.kind)
      } catch (err) {
        const graphQLError = err.graphQLErrors[0]
        if (graphQLError?.extensions.code === 'GENERATION_LIMIT_REACHED') {
          showSnackbarMessage(
            'warning',
            this.$t('alerts.content-creator.generate-text.limit-reached', {
              limit: graphQLError.extensions.limit
            })
          )
        } else {
          showSnackbarMessage('error', this.$t('alerts.content-creator.generate-text.error'))
        }
      }
    },

    async getImages () {
      const noImages = !partMapper[this.kind].includes('image') || !this.topicId || !this.kind || !this.imageSource
      if (noImages) {
        return
      }
      this.startLoading('image')
      const images = await {
        MATERIAL: this.getMaterials,
        STOCK: this.getStockPhotos,
        USER: this.getUserImages,
        AI: this.getAiImages
      }[this.imageSource]()
      this.generatedContent.image = images
      this.setDefaultSelectedImage()
      this.stopLoading('image')
    },

    async getMaterials () {
      if (!this.topic?.landingpage?.id) {
        return { }
      }
      let materials = []
      if (this.getFeature(this.featureNames.LANDINGPAGE).config.hasStaticMaterials) {
        materials = lightMaterials[this.topic.landingpage.slug] || []
      } else if (this.getFeature(this.featureNames.LANDINGPAGE).config.hasMaterials) {
        const { data: { materialsForContentCreator } } = await this.$apollo.query({
          query: GET_MATERIALS,
          variables: {
            input: {
              landingpageId: this.topic.landingpage.id
            }
          }
        })
        materials = materialsForContentCreator
      }
      const materialsWithFiles = materials.reduce((acc, item) => {
        if (item?.files?.length > 0) {
          item.files.forEach(file => acc.push(file?.previewFile || file?.file))
        }
        return acc
      }, []).map(({ cdnUrl, id }) => ({
        preview: setQueryParameters(cdnUrl, { height: 500 }),
        url: cdnUrl,
        id
      }))

      if (materialsWithFiles <= 0) {
        showSnackbarMessage('info', this.$t('alerts.content-creator.material.info'))
        return { }
      }
      return { choices: materialsWithFiles }
    },

    async getAiImages () {
      this.$tracking.event('Content Creator', 'Clicked', 'AI-Image Generation')
      const { data: { generatedImages } } = await this.$apollo.query({
        // Otherwise the function resolves with the cached data and doesn't wait for the network result
        fetchPolicy: 'no-cache',
        query: GET_GENERATED_IMAGES,
        variables: {
          input: {
            topicId: this.topicId,
            perPage: 50
          }
        }
      })

      this.$tracking.event('Content Creator', 'Generated', 'AI-Image')
      return {
        choices: [
          ...generatedImages.images.map(({ url, id }) => ({
            preview: setQueryParameters(url, { height: 500 }),
            url,
            id
          })),
          {
            id: 'fetchMore',
            text: generatedImages.total === 0 && this.$t('content-creator.creator.generate-first-image'),
            button: this.$t('buttons.content-creator.generate-image'),
            fetchMore: () => this.generateImage()
          }
        ]
      }
    },

    async getUserImages () {
      const { data: { userImages } } = await this.$apollo.query({
        // Otherwise the function resolves with the cached data and doesn't wait for the network result
        fetchPolicy: 'no-cache',
        query: GET_USER_IMAGES,
        variables: {
          input: {
            topicId: this.topicId
          }
        }
      })
      return {
        choices: [
          ...userImages.images.map(({ url, id }) => ({
            preview: setQueryParameters(url, { height: 500 }),
            url,
            id
          })),
          {
            id: 'uploadImage',
            fetchMore: (file) => this.uploadImage(file)
          }
        ]
      }
    },
    async uploadImage (file) {
      this.startLoading('image')
      try {
        await this.$apollo.mutate({
          mutation: UPLOAD_USER_IMAGE,
          variables: {
            input: {
              topicId: this.topicId,
              image: file
            }
          }
        })
      } catch (err) {
        showSnackbarMessage('error', this.$t('alerts.content-creator.upload-image.error'))
      }
      this.stopLoading('image')
      this.generatedContent.image = await this.getUserImages()
      this.setDefaultSelectedImage()
    },
    async generateImage () {
      this.startLoading('image')
      try {
        await this.$apollo.mutate({
          mutation: GENERATE_IMAGE,
          variables: {
            input: {
              topicId: this.topicId
            }
          },
          refetchQueries: ['getGenerationStats']
        })
      } catch (err) {
        const graphQLError = err.graphQLErrors[0]
        if (graphQLError?.extensions.code === 'GENERATION_LIMIT_REACHED') {
          showSnackbarMessage(
            'warning',
            this.$t('alerts.content-creator.generate-ai-image.limit-reached', {
              limit: graphQLError.extensions.limit
            })
          )
        } else {
          showSnackbarMessage('error', this.$t('alerts.content-creator.generate-ai-image.error'))
        }
      }
      this.stopLoading('image')
      this.generatedContent.image = await this.getAiImages()
      this.setDefaultSelectedImage()
    },

    async getStockPhotos () {
      this.$tracking.event('Content Creator', 'Clicked', 'Stockphoto Download')
      this.stockPhotos.page = 1
      const images = await this.fetchStockPhotos()
      return {
        choices: [
          ...images,
          {
            id: 'fetchMore',
            button: this.$t('buttons.content-creator.load-more'),
            fetchMore: () => this.getMoreStockPhotos()
          }
        ]
      }
    },
    async getMoreStockPhotos () {
      if (this.stockPhotos.page * this.stockPhotos.perPage >= this.stockPhotos.limit) {
        showSnackbarMessage('info', this.$t('alerts.content-creator.generate-stock-image.limit-reached', { limit: this.stockPhotos.limit }))
        return { }
      }
      this.stockPhotos.page++
      const images = await this.fetchStockPhotos()
      this.generatedContent.image = {
        choices: [
          ...images,
          ...this.generatedContent.image.choices
        ]
      }
    },
    async fetchStockPhotos () {
      this.startLoading('image')
      const { data: { stockPhotos } } = await this.$apollo.query({
        query: GET_STOCK_PHOTOS,
        variables: {
          input: {
            topic: this.topicId,
            page: this.stockPhotos.page,
            per_page: this.stockPhotos.perPage
          }
        }
      })
      this.stopLoading('image')

      this.$tracking.event('Content Creator', 'Loaded', 'Stockphoto')

      if (stockPhotos.photos.length === 0) {
        showSnackbarMessage('info', this.$t('alerts.content-creator.generate-stock-image.info'))
        return []
      }
      return stockPhotos.photos.map(photo => ({
        preview: setQueryParameters(photo.src.original, { h: 500, w: 500, auto: 'compress', fit: 'crop' }),
        url: setQueryParameters(photo.src.original, { h: 1500, w: 1500, auto: 'compress', fit: 'crop' }),
        id: photo.id
      }))
    },
    openSocialMediaDialog () {
      this.showSocialMediaDialog = true
    },

    goToUpgrade () {
      this.$tracking.event('Content Creator', 'Clicked', 'Upgrade', 'Textkind')
      this.$router.push({ name: 'Settings', params: { section: 'contract' }, query: { showUpgrade: '1' } })
    }
  },
  apollo: {
    topics: {
      query: GET_ACTIVE_TOPICS
    },
    lastGeneratedText: {
      query: GET_LAST_GENERATED_TEXT,
      variables () {
        return {
          input: {
            topicId: this.topicId,
            textKind: this.kind
          }
        }
      },
      skip () {
        return !this.topic?.id
      },
      // Using cache caused issues with re-generation of content. `update` or `result` is called once for the cached data
      // and once again for the network result. Unfortunately if cached data is empty (and network result is not)
      // generation of new content is unnecessarily triggered. I think in this case it's fine using only the cache
      // as I don't seee the case where one would switch text kind or topic forth and back during the same session.
      fetchPolicy: 'network-only',
      result ({ data }) {
        if (data.lastGeneratedText?.choices?.length > 0) {
          this.generatedContent.text = {
            choices: data.lastGeneratedText.choices.map(text => this.addLinkToText(text))
          }
          this.setDefaultSelectedText()
        } else {
          if (this.topicId && this.kind) {
            this.generate()
          }
        }
      }
    },
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>

<style scoped>
  @media (max-width: 960px) {
    .generate-btn {
      margin-left: 0;
      margin-right: 0;
    }
    .generate-btn, .social-media-btn {
      width: 100%;
    }
  }

  .pointer {
    cursor: pointer;
  }

  /* Imitate original behavior of the component */
  .selection-label {
    max-width: 90%;
  }

  .needs-upgrade-subtitle {
    font-weight: normal !important;
  }

  ::v-deep fieldset   {
    min-width: 64px;
    padding: 0 16px;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.12);
  }

  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
  }

</style>
