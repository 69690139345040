<template>
  <div>
    <v-btn
      class="w-full py-6 justify-start"
      :disabled="!isFacebookConnected"
      @click="connectToInstagram"
    >
      <v-img
        :src="KindTypeIcon.instagramPost"
        width="30"
        height="30"
        class="mr-2 flex-grow-0"
      />
      {{ $t('buttons.social-media.connect-instagram') }}
    </v-btn>
    <v-alert
      v-if="!isFacebookConnected"
      type="info"
      dense
      tile
      text
      class="mb-0"
    >
      {{ $t('tooltips.social-media.connect-facebook-first') }}
    </v-alert>
  </div>
</template>

<script>
import { SocialMedia } from '../../enums/SocialMedia'
import {
  facebookSDKInitialized,
  initFbSDK,
  loginToInstagram
} from '@/lib/facebook'
import { KindTypeIcon } from '../../enums/KindType'
import feature from '@/mixins/feature'

export default {
  mixins: [feature],
  props: {
    connectionWrapper: {
      type: Function,
      required: true
    },
    isFacebookConnected: {
      type: Boolean,
      default: false
    },
    featureConfig: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      KindTypeIcon
    }
  },
  methods: {
    async connectToInstagram () {
      if (!this.featureConfig.postToInstagram) {
        this.$emit('featureEnabled', false)
        return
      }

      await this.connectionWrapper('Instagram', async () => {
        if (!facebookSDKInitialized()) {
          await initFbSDK()
        }
        await loginToInstagram()
        return {
          type: SocialMedia.INSTAGRAM
        }
      })
    }
  }
}
</script>
