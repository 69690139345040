<template>
  <div class="w-full">
    <v-form
      ref="addressForm"
      v-model="isFormValid"
      name="company-address-data"
      @change="error = ''"
    >
      <v-row class="ma-0 pa-0 mb-2">
        <v-text-field
          v-model.lazy="companyData.name"
          outlined
          :label="$t('settings.company.address-data.company-name')"
          :placeholder="$t('settings.company.address-data.company-placeholder')"
          :rules="[rules.length({max: 47}) ]"
          :loading="isLoading.name"
          @click="onClick('Company Name', companyData.name)"
          @input="(val) => update('name', val, 'Company Name')"
        />
      </v-row>
      <v-row class="ma-0 pa-0 mb-2">
        <v-text-field
          v-model.lazy="companyData.street"
          outlined
          :label="$t('settings.company.address-data.street')"
          :placeholder="$t('settings.company.address-data.street-placeholder')"
          :loading="isLoading.street"
          @click="onClick('Street and House Number', companyData.street)"
          @input="(val) => update('street', val, 'Street and House Number')"
        />
      </v-row>
      <v-row class="d-flex justify-space-between ma-0 pa-0 mb-2">
        <v-col
          cols="12"
          md="4"
          class="pr-0 pa-0 pr-md-2"
        >
          <v-text-field
            v-model.lazy="companyData.zip"
            outlined
            :label="$t('labels.zip')"
            type="number"
            placeholder="12345"
            :rules="[rules.zip, rules.length({min: 4, max: 5})]"
            :loading="isLoading.zip"
            @click="onClick('ZIP', companyData.zip)"
            @input="(val) => update('zip', val, 'ZIP')"
          />
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="pr-0 pa-0"
        >
          <v-text-field
            v-model.lazy="companyData.city"
            outlined
            :label="$t('labels.city')"
            :placeholder="$t('labels.city-example')"
            :loading="isLoading.city"
            @click="onClick('City', companyData.city)"
            @input="(val) => update('city', val, 'City')"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 mb-2">
        <v-select
          v-model.lazy="companyData.country"
          :items="availableCountryItems"
          :label="$t('labels.country')"
          outlined
          required
          :rules="[rules.required]"
          @click="onClick('Country', companyData.country)"
          @change="(val) => update('country', val, 'Country')"
        />
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { zip, length, required } from '@/lib/validation'
import countries from '@/mixins/countries'

export default {
  mixins: [countries],
  props: {
    name: {
      type: String,
      default: ''
    },
    address: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      companyData: {
        name: this.name,
        street: this.address?.street,
        zip: this.address?.zip,
        city: this.address?.city,
        country: this.address?.country?.toUpperCase()
      },
      debounceTimeout: null,
      spinnerTimeout: null,
      isLoading: {
        name: false,
        street: false,
        city: false,
        zip: false,
        country: false
      },
      isFormValid: false
    }
  },
  watch: {
    name () {
      this.companyData.name = this.name
    },
    address () {
      this.companyData.street = this.address.street
      this.companyData.zip = this.address.zip
      this.companyData.city = this.address.city
      this.companyData.country = this.address.country?.toUpperCase()
    }
  },
  created () {
    this.rules = { zip, length, required }
  },
  unmounted () {
    clearTimeout(this.spinnerTimeout)
    clearTimeout(this.debounceTimeout)
  },
  methods: {
    onClick (label, value) {
      this.$tracking.event('Settings', 'Clicked', label, value)
    },
    async update (name, value, label) {
      this.companyData[name] = value
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.isLoading[name] = true
        this.spinnerTimeout = setTimeout(async () => {
          const isFormValid = await this.$refs.addressForm.validate()
          if (isFormValid) {
            this.$emit('saveSettings', { ...this.companyData })
            this.$tracking.event('Settings', 'Edited', label)
          }
          this.isLoading[name] = false
        }, 1000)
      }, 2000)
    }
  }
}
</script>
