<template>
  <div :style="cssProps">
    <v-expand-transition>
      <v-alert
        v-if="error"
        type="error"
        dense
        tile
        text
      >
        {{ getErrorMessage(error) }}
      </v-alert>
    </v-expand-transition>

    <div>
      <v-form
        name="auth-login"
        :class="{'markero': isPlatformMarkero}"
        @submit="login"
      >
        <v-text-field
          v-model.trim="email"
          :label="$t('login.form.email')"
          type="email"
          outlined
          hide-details
          :class="{'mb-5': isPlatformMarkero, 'mb-4': !isPlatformMarkero}"
          prepend-inner-icon="mdi-email-outline"
        />

        <v-text-field
          v-model="password"
          :label="$t('login.form.password')"
          type="password"
          hide-details
          outlined
          prepend-inner-icon="mdi-lock-outline"
        />

        <div
          class="justify-end d-flex "
          :class="{'mt-2 mb-4': !isPlatformMarkero}"
        >
          <router-link
            :to="{ name: 'password-reset-request' }"
            :style="{color: isPlatformMarkero ? $vuetify.theme.themes.light.markero.blue : 'primary'}"
            class="font-weight-bold no-border forgot-password-link"
          >
            {{ $t('login.form.forgotPassword') }}
          </router-link>
        </div>
        <div
          class="px-0 d-flex flex-column"
        >
          <loading-button
            :color="isPlatformMarkero ? $vuetify.theme.themes.light.markero.blue : 'primary'"
            type="submit"
            :class="{'my-8 login-btn': isPlatformMarkero, 'mb-5': !isPlatformMarkero}"
            style="height:50px"
            :loading="submitting"
          >
            {{ $t('login.form.submit') }}
          </loading-button>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import LoadingButton from '@/components/loading-button'
import { supportContact } from '@/lib/features'
import brandingMixin from '@/mixins/branding'

export default {
  components: {
    LoadingButton
  },
  mixins: [brandingMixin],
  data: () => ({
    email: '',
    password: '',
    error: null,
    submitting: false
  }),

  computed: {
    cssProps () {
      return {
        '--dark-blue': this.$vuetify.theme.themes.light.markero.darkBlue,
        '--dark-grey': this.$vuetify.theme.themes.light.markero.darkGrey
      }
    },
    errorMessages () {
      return {
        emptyFields: this.$t('login.errors.emptyFields'),
        accessDenied: this.$t('login.errors.accessDenied'),
        tooManyAttempts: this.$t('login.errors.tooManyAttempts'),
        blockedUser: this.$t('login.errors.blockedUser', { supportContact: supportContact.config.email }),
        unknown: this.$t('login.errors.unknown')
      }
    }
  },
  methods: {
    async login (evt) {
      evt.preventDefault()

      this.error = null

      const formValid = this.email && this.password

      if (formValid) {
        this.submitting = true

        try {
          await this.$auth.login(this.email, this.password)
        } catch (err) {
          if (err.code === 'access_denied') {
            this.error = 'accessDenied'
          } else if (err.code === 'too_many_attempts') {
            this.error = 'tooManyAttempts'
          } else if (err.code === 'blocked_user') {
            this.error = 'blockedUser'
          } else {
            this.error = 'unknown'
          }
          Sentry.addBreadcrumb({
            category: 'auth',
            message: 'Login failed'
          })
          Sentry.captureException(err)
        }

        this.submitting = false
      } else {
        this.error = 'emptyFields'
      }
    },
    getErrorMessage (code) {
      return this.errorMessages[code]
    }
  }
}
</script>
<style scoped>
/* No Border Styles */
.no-border {
  text-decoration: none;
}

/* Markero Styles */
.markero .forgot-password-link,
.markero .login-btn,
::v-deep .markero .theme--light.v-label {
  font-family: 'Poppins', sans-serif;
}

/* Forgot Password Link Styles */
.markero .forgot-password-link {
  margin-top: 10px;
}

::v-deep .markero .forgot-password-link:hover {
  color: var(--dark-blue) !important;
}

/* Login Button Styles */
.markero .login-btn {
  font-size: 16px;
  font-weight: 700;
  color: white;
  height: fit-content !important;
  padding: 14px 34px !important;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(47, 22, 232, 0.25) !important;
  text-transform: none;
  font-style: normal;
  line-height: normal;
}

/* Vuetify Specific Overrides */
::v-deep .markero .theme--light.v-btn:hover::before {
  background-color: var(--dark-blue);
}

::v-deep .markero .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: #E4E4E4;
}

::v-deep .markero .theme--light.v-input input {
  color: var(--dark-grey);
}

::v-deep .markero .v-text-field--enclosed .v-input__prepend-inner {
  margin: 12px 0;
}

::v-deep .v-text-field--outlined .v-label {
  top: 15px;
}

::v-deep .markero .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 30px;
}

</style>
