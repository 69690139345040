import { render, staticRenderFns } from "./PaymentRegistration.vue?vue&type=template&id=214a36db&scoped=true&"
import script from "./PaymentRegistration.vue?vue&type=script&lang=js&"
export * from "./PaymentRegistration.vue?vue&type=script&lang=js&"
import style0 from "./PaymentRegistration.vue?vue&type=style&index=0&id=214a36db&prod&scoped=true&lang=css&"
import style1 from "./PaymentRegistration.vue?vue&type=style&index=1&id=214a36db&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "214a36db",
  null
  
)

export default component.exports