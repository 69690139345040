<template>
  <step-container
    v-if="countOfGuides > 0"
    :required-data="requiredData"
    :data="{ selectedLandingpages }"
    :on-submit="submit"
    has-steps
    :hide-secondary-actions="hideSecondaryActions"
    :name="'update-booked-landingpages'"
    style="padding: 0px !important;"
    v-on="$listeners"
  >
    <div class="px-1 px-md-2">
      <div v-if="!hideDescription">
        {{ $t('onboarding.landingpages.selectedGuides', { count: countOfGuides }) }}
        {{ $t('onboarding.landingpages.chooseGuides', { count: countOfGuides }) }}
      </div>
      <v-card
        v-for="({name, slug}) in guides"
        :key="slug"
        class="my-6 px-8 py-4"
      >
        <v-row>
          <v-col
            cols="12"
            sm="8"
            lg="10"
          >
            <v-checkbox
              v-model="selectedLandingpages"
              class="mt-0"
              :value="slug"
              hide-details
              :disabled="allSelected && !selectedLandingpages.includes(slug)"
            >
              <template v-slot:label>
                <div
                  class="px-4"
                >
                  <h3>
                    {{ name }}
                  </h3>
                  <h4
                    v-if="teaserSubtitle(slug)"
                    class="pt-2"
                  >
                    {{ teaserSubtitle(slug) }}
                  </h4>
                  <div
                    class="py-2"
                  >
                    {{ teaserText(slug) }}
                  </div>
                </div>
              </template>
            </v-checkbox>
            <div
              class="ml-6 flex-wrap"
            >
              <a
                class="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
                :href="`https://${exampleCompanySlug}.immowissen.org/${slug}`"
              >
                <v-btn class="mx-6 my-2">
                  {{ $t('onboarding.landingpages.exampleLandingpage') }}
                </v-btn></a>
              <a
                class="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
                :href="material(slug)"
              >
                <v-btn class="mx-6 my-2">
                  {{ $t('onboarding.landingpages.exampleGuide') }}
                </v-btn></a>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            lg="2"
          >
            <v-img
              class="elevation-4"
              centered
              contain
              :src="previewImage(slug)"
            />
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-alert
      v-if="allSelected"
      text
      class="mx-4"
      type="info"
    >
      {{ $t('onboarding.landingpages.allSelected', { count: countOfGuides }) }}
      {{ $t('onboarding.landingpages.selectionExhausted') }}
      {{ $t('onboarding.landingpages.removeSelection') }}
      {{ $t('onboarding.landingpages.contactSales', { count: countOfGuides }) }}
    </v-alert>
  </step-container>
</template>

<script>
import * as Sentry from '@sentry/vue'
import StepContainer from '@/modules/company/StepContainer.vue'
import BOOKABLE_LANDINGPAGE_TEMPLATES from './queries/BookableLandingpageTemplates.gql'
import MATERIAL_PREVIEW from './queries/MaterialPreview.gql'
import UPDATE_BOOKED_LANDINGPAGES from './queries/UpdateBookedLandingpages.gql'
import { EXAMPLE_COMPANIES, LANDNINGPAGE_TEASER } from '@/components/forms/labels'

export default {
  components: { StepContainer },
  props: {
    companyId: {
      type: String,
      required: true
    },
    country: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      required: true
    },
    countOfGuides: {
      type: Number,
      required: true
    },
    bookedLandingpageSlugs: {
      type: Array,
      required: true
    },
    hideDescription: {
      type: Boolean,
      default: false
    },
    hideSecondaryActions: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      companyName: this.name,
      bookableLandingpageTemplates: [],
      onboardingMaterialPreview: [],
      selectedLandingpages: this.bookedLandingpageSlugs
    }
  },
  computed: {
    guides () {
      return this.bookableLandingpageTemplates.filter(({ type }) => type === 'GUIDE')
    },
    allSelected () {
      return this.selectedLandingpages.length >= this.countOfGuides
    },
    exampleCompanySlug () {
      return EXAMPLE_COMPANIES[this.variant]
    }
  },
  apollo: {
    bookableLandingpageTemplates: {
      query: BOOKABLE_LANDINGPAGE_TEMPLATES,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    onboardingMaterialPreview: {
      query: MATERIAL_PREVIEW,
      variables () {
        return {
          companySlug: this.exampleCompanySlug
        }
      }
    }
  },
  methods: {
    requiredData () {
      return { bookedLandingpageSlugs: this.allSelected }
    },
    teaserText (slug) {
      if (!LANDNINGPAGE_TEASER[slug]) {
        Sentry.captureMessage(`Missing Teaser for ${slug}`)
        return ''
      }
      return LANDNINGPAGE_TEASER[slug].text
    },
    teaserSubtitle (slug) {
      if (!LANDNINGPAGE_TEASER[slug]) {
        Sentry.captureMessage(`Missing Teaser for ${slug}`)
        return ''
      }
      return LANDNINGPAGE_TEASER[slug].subtitle
    },
    material (slug) {
      return this.onboardingMaterialPreview.find(({ landingpageSlug }) => landingpageSlug === slug)?.file
    },
    previewImage (slug) {
      return this.onboardingMaterialPreview.find(({ landingpageSlug }) => landingpageSlug === slug)?.preview
    },
    async submit ({ selectedLandingpages }) {
      await this.$apollo.mutate({
        mutation: UPDATE_BOOKED_LANDINGPAGES,
        variables: {
          input: {
            companyId: this.companyId,
            bookedLandingpageSlugs: selectedLandingpages
          }
        }
      })
    }
  }
}
</script>
