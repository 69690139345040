<template>
  <v-form
    ref="inputData"
    v-model="isValid"
    name="create-lead-by-user"
    @submit.prevent="uploadSingleData"
    @change="error = ''"
  >
    <v-container>
      <v-expand-transition>
        <v-alert
          v-if="error"
          type="error"
          dense
          tile
          text
        >
          {{ error }}
        </v-alert>
      </v-expand-transition>
    </v-container>
    <v-container>
      <CreateLeadForm
        :lead-data="leadData"
      />
      <p><sup>*</sup>= Pflichtfeld</p>
    </v-container>
  </v-form>
</template>
<script>
import CREATE_LEAD_BY_USER from './queries/CreateLeadByUser.gql'
import { required, zip, email, integer } from '@/lib/validation'
import CreateLeadForm from '@/components/forms/CreateLead.vue'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: {
    CreateLeadForm
  },
  props: {
    leadData: {
      type: Object,
      default: () => ({})
    },
    country: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      error: '',
      isValid: false
    }
  },
  computed: {
    rules () {
      return {
        zip,
        email,
        required,
        integer
      }
    }
  },
  mounted () {
    bus.$on(eventNames.SUBMIT_CREATE_LEAD_FORM, this.handleSubmit)
    bus.$on(eventNames.RESET_CREATE_LEAD_FORM, this.handleReset)
  },
  methods: {
    async handleSubmit () {
      const { isDataProcessingPermissionGranted, ...personalData } = this.leadData
      this.error = ''
      if (this.$refs.inputData.validate()) {
        try {
          // create lead
          const { data } = await this.$apollo.mutate({
            mutation: CREATE_LEAD_BY_USER,
            variables: {
              input: {
                personalData,
                isExistingCustomer: true,
                isDataProcessingPermissionGranted
              }
            }
          })
          this.$emit('createdLeadResult', data)
          this.handleReset()
        } catch (error) {
          const errorMessage = error.graphQLErrors[0]?.message
          if (errorMessage === 'INVALID_PHONE_ERROR') {
            this.error = this.$t('alerts.create-lead.error-phone')
          } else if (errorMessage === 'ENTITY_ALREADY_EXISTS_ERROR') {
            this.error = this.$t('alerts.create-lead.error-exists')
          } else {
            this.error = this.$t('alerts.create-lead.error')
          }
        }
      }
    },
    handleReset () {
      this.$refs.inputData.$el.reset()
      this.error = ''
      this.isValid = false
    }
  }
}
</script>
