<template>
  <div>
    <v-dialog
      :value="show"
      width="550"
      @input="close"
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>
              {{ $t('buttons.return-call.cta') }}
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="close"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mt-8 text-body-1 user-form">
          <div>
            <v-form
              ref="form"
              v-model="valid"
              name="return-call-reason"
            >
              <v-text-field
                v-model="returnCallReason"
                :label="$t('settings.features.return-call.labels.add')"
                outlined
                :rules="[rules.required]"
              />
            </v-form>
          </div>
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-btn
            text
            @click="close"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-spacer />

          <v-btn
            color="primary"
            class="px-4 mr-2 mb-2"
            :disabled="isLoading"
            :loading="isLoading"
            @click="submit"
          >
            {{ $t('labels.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import eventBus, { eventNames } from '@/lib/eventBus'
import { required } from '@/lib/validation'

export default {
  props: {
    show: { type: Boolean, default: false }
  },
  data () {
    return {
      rules: { required },
      isLoading: false,
      valid: false,
      returnCallReason: null
    }
  },
  methods: {
    async close () {
      this.$refs.form.resetValidation()
      this.$emit('close')
    },
    async submit () {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }
      await this.createReturnCallReason()
    },
    async createReturnCallReason () {
      try {
        this.isLoading = true
        eventBus.$emit(eventNames.SHOW_SNACKBAR, {
          color: 'success',
          text: this.$t('alerts.return-call.success')
        })
        this.close()
        this.$emit('addReason', this.returnCallReason)
        this.returnCallReason = null
        this.$tracking.event('Settings', 'Edited', 'Add callback reason')
      } catch (e) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.return-call.error') })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
