<template>
  <v-container class="pa-0 layout-container">
    <template v-if="needsPaymentMethod">
      <PaymentMethod :country="country" />
      <v-divider class="my-4" />
      <BillingData :token="billingSelfServicePortalDetails?.Token" />
      <v-divider class="my-4" />
    </template>
    <Invoice :billing-self-service-portal-details="billingSelfServicePortalDetails" />
  </v-container>
</template>

<script>
import PaymentMethod from './PaymentMethod'
import BillingData from './BillingData'
import Invoice from './Invoice'
import SELF_SERVICE_PORTAL_URL from '@/modules/settings/accountSettings/queries/SelfServicePortalUrl.gql'
import { billwerkApi } from '@/modules/billwerk/lib'

export default {
  components: {
    PaymentMethod,
    BillingData,
    Invoice
  },

  data () {
    return {
      needsPaymentMethod: null,
      country: null
    }
  },

  watch: {
    async billingSelfServicePortalDetails (data) {
      if (data?.Token && this.needsPaymentMethod === null) {
        const contract = await billwerkApi.getContractDetails(data.Token)
        this.needsPaymentMethod = contract.CurrentPlan.PlanName !== 'Beginner'
        this.country = contract.Customer.Address.Country // country in uppercase (DE)
      }
    }
  },

  apollo: {
    billingSelfServicePortalDetails: {
      query: SELF_SERVICE_PORTAL_URL
    }
  }

}
</script>
