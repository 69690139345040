<template>
  <step-container
    :required-data="requiredData"
    :data="data"
    :on-submit="submit"
    :has-steps="isOnboarding"
    :name="'update-company'"
    v-on="$listeners"
  >
    <company-data
      v-if="isOnboarding"
      :data="data"
      is-onboarding
    />

    <v-card
      v-else
      flat
    >
      <company-data
        class="mx-4"
        :data="data"
      />
    </v-card>
  </step-container>
</template>

<script>
import UPDATE_COMPANY from './queries/UpdateCompany.gql'
import PENDING_CHANGES_FOR_REGENERATION from '@/queries/PendingChangesForRegeneration.gql'
import StepContainer from './StepContainer.vue'
import CompanyData from '@/components/forms/CompanyData'

export default {
  components: { StepContainer, CompanyData },
  props: {
    companyId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    address: {
      type: Object,
      default: () => ({})
    },
    links: {
      type: Object,
      default: () => ({})
    },
    commission: {
      type: String,
      default: ''
    },
    isOnboarding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: {
        companyId: this.companyId,
        name: this.name,
        street: this.address.street,
        city: this.address.city,
        zip: this.address.zip,
        country: this.address.country,
        website: this.links.website,
        privacy: this.links.privacy,
        imprint: this.links.imprint,
        about: this.links.about,
        commission: this.commission
      }
    }
  },
  methods: {
    requiredData ({ name, street, city, zip, country, website, privacy, imprint, about }) {
      return { name, street, city, zip, country, website, privacy, imprint, about }
    },
    async submit ({ companyId, name, website, privacy, imprint, about, street, city, zip, country, commission }) {
      await this.$apollo.mutate({
        mutation: UPDATE_COMPANY,
        variables: {
          input: {
            companyId,
            name,
            links: { website, privacy, imprint, about },
            address: { street, city, zip, country },
            commission
          }
        },
        refetchQueries: [{
          query: PENDING_CHANGES_FOR_REGENERATION,
          variables: { companyId: this.companyId }
        }]
      })
    }
  }
}
</script>
