<template>
  <v-text-field
    :value="industry"
    :label="$t('product-finder.industry-placeholder')"
    hide-details
    outlined
    @click="onIndustryClick()"
    @input="onInput"
  />
</template>

<script>

import { debounce } from '@/lib/debounce'

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      industry: this.value,
      debouncedEmit: null
    }
  },
  created () {
    this.debouncedEmit = debounce(this.emitInput, 2000)
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    },
    onInput (value) {
      this.debouncedEmit(value)
    },
    onIndustryClick () {
      this.$tracking.event('Account Creation', 'Clicked', 'Industry input clicked')
    }
  }
}

</script>

