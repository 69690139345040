<template>
  <v-select
    ref="paymentMethod"
    v-model="internalValue"
    :items="paymentMethodItems"
    :label="$t('labels.payment-method')"
    :rules="[rules.required]"
    required
    outlined
    @click="$emit('click', value)"
  >
    <template v-slot:item="{item}">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
        <template v-if="item.onlyYearly || item.debitReepayDisabled">
          <v-list-item-subtitle
            class="disabled-subtitle newline"
          >
            <span v-if="item.onlyYearly">{{ $t('billwerk.customer-form.payment-method.only-yearly', { method: item.text }) }}</span>
            <span v-else>
              {{ $t('billwerk.customer-form.payment-method.direct-debit-disabled') }}<br>
              <span v-if="isUpgrade">
                {{ $t('billwerk.customer-form.payment-method.direct-debit-disabled-upgrade.0') }}<br>
                {{ $t('billwerk.customer-form.payment-method.direct-debit-disabled-upgrade.1', { email }) }}
              </span>
              <span v-else>
                {{ $t('billwerk.customer-form.payment-method.direct-debit-disabled-register.0') }}
              </span>

            </span>
          </v-list-item-subtitle>
        </template>
      </v-list-item-content>
      <v-list-item-avatar v-if="item.onlyYearly || item.debitReepayDisabled">
        <v-icon color="grey">
          mdi-lock
        </v-icon>
      </v-list-item-avatar>
    </template>
  </v-select>
</template>

<script>
import { required } from '@/lib/validation'
import feature from '@/mixins/feature'
import featureNames from '@/lib/featureNames'

export const PaymentMethods = Object.freeze({
  BLACK_LABEL_INVOICE_PAYMENT: 'BlackLabel:InvoicePayment',
  BLACK_LABEL_PAYPAL: 'BlackLabel:PayPal',
  CREDIT_CARD_REEPAY: 'CreditCard:Reepay',
  APPLE_PAY_REEPAY: 'ApplePay:Reepay',
  DEBIT_REEPAY: 'Debit:Reepay',
  NONE_NONE: 'none:none'
})

export const paymentMethodLabels = {
  [PaymentMethods.BLACK_LABEL_INVOICE_PAYMENT]: 'settings.payment.payment-method.methods.invoice',
  [PaymentMethods.BLACK_LABEL_PAYPAL]: 'settings.payment.payment-method.methods.paypal',
  [PaymentMethods.CREDIT_CARD_REEPAY]: 'settings.payment.payment-method.methods.credit-card',
  [PaymentMethods.APPLE_PAY_REEPAY]: 'settings.payment.payment-method.methods.apple-pay',
  [PaymentMethods.DEBIT_REEPAY]: 'settings.payment.payment-method.methods.debit',
  [PaymentMethods.NONE_NONE]: 'settings.payment.payment-method.methods.none'
}

export default {
  components: { },
  mixins: [feature],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    isUpgrade: {
      type: Boolean,
      default: false
    },
    isPlatformMarkero: {
      type: Boolean,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: 'none:none'
    },
    isEuCountry: {
      type: Boolean,
      default: false
    },
    isYearly: {
      type: Boolean,
      default: false
    },
    disabledPaymentMethods: {
      type: Array,
      default: () => []
    }
  },

  data () {
    const defaultContact = this.$features.feature(featureNames.SUPPORT_CONTACT).config
    return {
      rules: { required },
      internalValue: this.value,
      email: defaultContact.support.email
    }
  },

  computed: {
    isDebitReepayDisabled () {
      return this.isPlatformMarkero && this.isEuCountry && this.currency === 'USD'
    },
    paymentMethodItems () {
      const { paymentMethodsOnlyYearly = [], paymentMethods = [] } = this.$features.feature(this.featureNames.BILLWERK).config || {}
      return paymentMethods
        .filter((method) => {
          // Show Apple Pay only if supported
          return method !== PaymentMethods.APPLE_PAY_REEPAY || window.ApplePaySession
        })
        .filter((method) => !this.disabledPaymentMethods.includes(method))
        .map((method) => ({
          text: this.$t(paymentMethodLabels[method]),
          value: method,
          disabled: (!this.isYearly && paymentMethodsOnlyYearly.includes(method)) || (method === PaymentMethods.DEBIT_REEPAY && this.isDebitReepayDisabled),
          debitReepayDisabled: method === PaymentMethods.DEBIT_REEPAY && this.isDebitReepayDisabled,
          onlyYearly: !this.isYearly && paymentMethodsOnlyYearly.includes(method)
        }))
        .concat(this.internalValue && (!paymentMethods.includes(this.internalValue) || this.disabledPaymentMethods.includes(this.internalValue))
          ? {
            text: this.$t(paymentMethodLabels[this.internalValue]),
            value: this.internalValue,
            disabled: true
          }
          : [])
    }
  },

  watch: {
    value (newValue) {
      this.internalValue = newValue
    },
    internalValue (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
