<template>
  <AuthView>
    <div class="flex-1">
      <h1 class="display-1 font-weight-black">
        <span>{{ $t("login.title") }}</span>
      </h1>

      <p class="mb-10 grey--text text--darken-1">
        {{ $t("login.subtitle", { platformName: readablePlatformName }) }}
      </p>
      <LoginForm />

      <div
        v-if="showSignup"
        class="text-center mt-4"
      >
        <span>{{ $t('login.register.question') }}</span>
        <a
          :style="{color: 'primary'}"
          href="/auth/signup"
          class="ml-2 primary--text font-weight-bold hover"
        >{{ $t('login.register.link') }}</a>
      </div>

      <v-card
        v-if="showConsulting"
        outlined
        class="mt-8"
      >
        <v-card-text class="d-flex">
          <v-icon
            color="gray"
            large
          >
            mdi-calendar-clock
          </v-icon>
          <div class="pa-4">
            <p class="mb-4">
              {{ $t('login.disclaimer') }}
            </p>
            <v-btn
              outlined
              center
              small
              color="primary"
              class="px-4 text-center"
              href="https://termin.bottimmo.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('login.action') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </AuthView>
</template>

<script>
import LoginForm from '../LoginForm'
import AuthView from '../../AuthView'
import brandingMixin from '@/mixins/branding'
import featureNames from '@/lib/featureNames'
import { consulting, signup } from '@/lib/features'
import { capitalizeFirstLetter } from '@/lib/capitalize'

export default {
  components: { LoginForm, AuthView },
  mixins: [brandingMixin],
  data () {
    return {
      featureNames
    }
  },
  computed: {
    showConsulting () {
      return consulting?.isActive
    },
    showSignup () {
      return signup?.isActive
    }
  },
  methods: { capitalizeFirstLetter }
}
</script>
<style scoped>
/* Hover Styles */
.hover:hover {
  cursor: pointer;
}
</style>
