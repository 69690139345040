<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="success"
          icon
          v-bind="attrs"
          v-on="on"
          @click="show = true"
        >
          <v-icon :alt="$t('settings.email.update-sender-dialog.labels.edit')">
            mdi-pencil-outline
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('settings.email.update-sender-dialog.labels.edit') }}</span>
    </v-tooltip>
    <v-dialog
      v-model="show"
      width="550"
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{ $t('settings.email.update-sender-dialog.title') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="show = false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mt-4 text-body-1">
          <div class="pb-4">
            <p>
              {{ $t('settings.email.update-sender-dialog.body-texts.0') }}
            </p>
            <v-form
              v-model="valid"
              name="update-sender"
              @submit.prevent="createSender"
            >
              <v-text-field
                v-model="name"
                autofocus
                :label="$t('labels.name')"
                outlined
                :rules="[rules.required]"
              />
              <v-text-field
                v-model="fromEmail"
                disabled
                :rules="[rules.email, rules.required]"
                outlined
                :label="$t('settings.email.update-sender-dialog.labels.from-email')"
                required
              />
              <v-text-field
                v-model="replyToEmail"
                :rules="[rules.email]"
                outlined
                :label="$t('settings.email.update-sender-dialog.labels.reply-to-email')"
              />
            </v-form>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            @click="show = false"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-spacer />

          <v-btn
            color="success"
            :disabled="isLoading"
            :loading="isLoading"
            text
            @click="createSender"
          >
            {{ $t('labels.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HOPPERMATION_SENDER from './queries/HoppermationSenders.gql'
import UPDATE_HOPPERMATION_SENDER from './queries/UpdateHoppermationSender.gql'
import eventBus, { eventNames } from '@/lib/eventBus'
import { email, required } from '@/lib/validation'

export default {
  props: {
    sender: { type: Object, required: true }
  },
  data () {
    return {
      rules: { email, required },
      show: false,
      isLoading: false,
      valid: false,
      name: this.sender.name,
      fromEmail: this.sender.fromEmail,
      replyToEmail: this.sender.replyToEmail
    }
  },
  methods: {
    async createSender () {
      if (!this.valid) {
        return
      }
      try {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: UPDATE_HOPPERMATION_SENDER,
          variables: {
            input: {
              senderId: this.sender.id,
              name: this.name,
              replyToEmail: this.replyToEmail
            }
          },
          refetchQueries: [{
            query: HOPPERMATION_SENDER,
            variables: { companyId: this.$auth.user.companyId }
          }]
        })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.email.update-sender.success') })
        this.show = false
      } catch (e) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.email.update-sender.error') })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
