var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isSlugEditable)?_c('div',{staticClass:"w-full"},[_c('v-form',{ref:"linksForm",attrs:{"name":"slug"},on:{"submit":_vm.submit},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{staticClass:"mb-2 ma-0 pa-0"},[_c('v-text-field',{ref:"slug",attrs:{"outlined":"","type":"url","label":"Ihre persönliche Landinpage URL","prefix":"https://","error":_vm.hasParentError,"error-messages":_vm.errorMessages,"succes":_vm.hasParentSuccess,"success-messages":_vm.successMessages,"suffix":_vm.landingpageDomain,"rules":[
          _vm.rules.required,
          _vm.rules.length({ min: 5 }),
          _vm.rules.matchPattern(
            /^[a-z0-9](-?[a-z0-9])*$/,
            _vm.$t('alerts.settings.company.slug-data.error-validation')
          )
        ],"loading":_vm.isLoading},on:{"input":_vm.update,"update:error":function($event){_vm.isFieldValid = !$event},"submit":_vm.submit},model:{value:(_vm.slugData),callback:function ($$v) {_vm.slugData=$$v},expression:"slugData"}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }