export const LanguageCodes = [
  'de-DE', // German (Germany)
  'de-AT', // German (Austria)
  'de-CH', // German (Switzerland)
  'en-GB', // English (UK)
  'en-US', // English (US)
  'af', // Afrikaans
  'sq', // Albanian
  'am', // Amharic
  'ar', // Arabic
  'az', // Azerbaijani
  'be', // Belarusian
  'bg', // Bulgarian
  'bn', // Bengali
  'bs', // Bosnian
  'ca', // Catalan
  'ceb', // Cebuano
  'co', // Corsican
  'cs', // Czech
  'cy', // Welsh
  'da', // Danish
  'el', // Greek
  'eo', // Esperanto
  'es', // Spanish
  'es-MX', // Spanish (Mexico)
  'et', // Estonian
  'eu', // Basque
  'fa', // Persian
  'fi', // Finnish
  'fr', // French
  'fr-CA', // French (Canada)
  'ga', // Irish
  'gd', // Scottish Gaelic
  'gl', // Galician
  'gu', // Gujarati
  'haw', // Hawaiian
  'he', // Hebrew
  'hi', // Hindi
  'hmn', // Hmong
  'hr', // Croatian
  'hu', // Hungarian
  'hy', // Armenian
  'id', // Indonesian
  'ig', // Igbo
  'is', // Icelandic
  'it', // Italian
  'ja', // Japanese
  'jv', // Javanese
  'ka', // Georgian
  'kk', // Kazakh
  'km', // Khmer
  'kn', // Kannada
  'ko', // Korean
  'ku', // Kurdish
  'ky', // Kyrgyz
  'la', // Latin
  'lb', // Luxembourgish
  'lo', // Lao
  'lt', // Lithuanian
  'lv', // Latvian
  'mg', // Malagasy
  'mi', // Maori
  'mk', // Macedonian
  'ml', // Malayalam
  'mn', // Mongolian
  'mr', // Marathi
  'ms', // Malay
  'mt', // Maltese
  'my', // Burmese
  'ne', // Nepali
  'nl', // Dutch
  'nb-NO', // Norwegian Bokmål
  'ny', // Chichewa
  'pa', // Punjabi
  'pl', // Polish
  'ps', // Pashto
  'pt', // Portuguese
  'pt-BR', // Portuguese (Brazil)
  'pt-PT', // Portuguese (Portugal)
  'ro', // Romanian
  'ru', // Russian
  'rw', // Kinyarwanda
  'sd', // Sindhi
  'si', // Sinhala
  'sk', // Slovak
  'sl', // Slovenian
  'sm', // Samoan
  'sn', // Shona
  'so', // Somali
  'sr', // Serbian
  'st', // Southern Sotho
  'su', // Sundanese
  'sv', // Swedish
  'sw', // Swahili
  'ta', // Tamil
  'te', // Telugu
  'tg', // Tajik
  'th', // Thai
  'tk', // Turkmen
  'tl', // Tagalog
  'tr', // Turkish
  'tt', // Tatar
  'ug', // Uyghur
  'uk', // Ukrainian
  'ur', // Urdu
  'uz', // Uzbek
  'vi', // Vietnamese
  'xh', // Xhosa
  'yi', // Yiddish
  'yo', // Yoruba
  'zh', // Chinese
  'zh-CN', // Chinese (Simplified)
  'zh-TW', // Chinese (Traditional)
  'zu' // Zulu
]
