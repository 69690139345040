<template>
  <step-container
    :required-data="requiredData"
    :data="{ validated }"
    :name="'email-validation'"
    :on-submit="()=>({})"
    has-steps
    v-on="$listeners"
  >
    <v-alert
      v-if="!email"
      text
      class="custom-alert"
      type="warning"
    >
      {{ $t('onboarding.emailValidation.noEmail') }}
    </v-alert>
    <v-alert
      v-if="!companyName"
      text
      class="custom-alert"
      type="warning"
    >
      {{ $t('onboarding.emailValidation.noCompanyName') }}
    </v-alert>
    <v-alert
      v-if="validated"
      text
      class="custom-alert"
      type="success"
    >
      {{ $t('onboarding.emailValidation.success', { name: defaultSender.name, email: defaultSender.fromEmail }) }}<br>
      {{ $t('onboarding.emailValidation.changeAfterOnboarding') }}
    </v-alert>
    <EmailVerification
      v-if="email && companyName"
      :default-sender="defaultSender"
    />
  </step-container>
</template>

<script>
import HOPPERMATION_DEFAULT_SENDER from '@/modules/emailVerification/queries/HoppermationDefaultSender.gql'
import HOPPERMATION_ORGANIZATION from '@/modules/emailVerification/queries/HoppermationOrganization.gql'
import StepContainer from '@/modules/company/StepContainer.vue'
import EmailVerification from '@/modules/emailVerification'
import featureMixin from '@/mixins/feature'

export default {
  components: { StepContainer, EmailVerification },
  mixins: [featureMixin],
  props: {
    isOnboarding: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    },
    email: {
      type: String,
      default: ''
    },
    companyName: {
      type: String,
      default: ''
    }
  },
  computed: {
    validated () {
      return this.organization?.status === 'ACTIVE'
    }
  },
  apollo: {
    defaultSender: {
      query: HOPPERMATION_DEFAULT_SENDER,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    organization: {
      query: HOPPERMATION_ORGANIZATION,
      pollInterval: 60000,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  },
  methods: {
    requiredData () {
      return { emailValidation: this.validated }
    }
  }
}
</script>

<style>
@media (max-width: 600px) {
  .custom-alert {
    display: flex;
    justify-content: left;
    width: auto;
    word-break: break-word;
  }
}

</style>
